import { Component, EventEmitter, Input, Output } from '@angular/core';
import { OnInit, AfterViewChecked, HostListener } from '@angular/core';
import { ViewCell } from 'ng2-smart-table';

export class Intermediate {
    public Disponible = 0;
    public Professor: object = {
        2941: "--ASIGNAR DESPUÉS",
    }
    public Grups: object = {
        0: " NO GROUP",
    }
}
// INICIO COMPONENTE PARA EL NG SMART TABLE GRUPOS
@Component({
    selector: 'button-view-groups',
    template: `
    {{ renderValue }}
  `
})
export class ButtonViewCourseGroups implements ViewCell, OnInit, AfterViewChecked {
    constructor(
        public intermediate: Intermediate,
    ) { }
    renderValue: string;

    @Input() value: string | number;
    @Input() rowData: any;
    @Output() save: EventEmitter<any> = new EventEmitter()
    ngOnInit() {
        // console.debug("aca esta el render despues")
        //  console.debug(this.intermediate.Grups)
        this.renderValue = this.intermediate.Grups[this.value];
    }
    ngAfterViewChecked() {
        this.renderValue = this.intermediate.Grups[this.value];
    }
}
// FIN COMPONENTE PARA EL NG SMART TABLE GRUPOS


// INICIO COMPONENTE PARA EL NG SMART TABLE ButtonViewdPhysicalTest
@Component({
    selector: 'button-view-dateChanged',
    template: `
    <div *ngIf="renderValue">
  <b *ngIf="renderValue=='Si'"  style='color: green;'>  {{renderValue}}</b>
  <b *ngIf="renderValue=='No'"   >  {{renderValue}}</b>
   </div>   `
})
export class ButtonViewdPhysicalTest implements ViewCell, OnInit, AfterViewChecked {
    constructor(

    ) { }
    renderValue: string;
    @Input() value: string | number;
    @Input() rowData: any;
    @Output() save: EventEmitter<any> = new EventEmitter()
    ngOnInit() {
        // console.log("aca esta el render despues")
        //  console.log(this.intermediate.Grups)
       
        if (this.value!=null || this.value!=undefined) {
            if (this.value.toString() == "true" || this.value.toString() == "1" )
                this.renderValue = "Si"
            if (this.value.toString() == "false" || this.value.toString() == "0")
                this.renderValue = "No"
        }
    }
    ngAfterViewChecked() {
        this.renderValue = String(this.value);
    }

}
// FIN COMPONENTE PARA EL NG SMART TABLE ButtonViewdPhysicalTest




// INICIO COMPONENTE PARA EL NG SMART TABLE USUARIO
@Component({
    selector: 'button-view-courses',
    template: `
    {{ renderValue }}
  `
})
export class ButtonViewProfessors implements ViewCell, OnInit, AfterViewChecked {
    constructor(
        public intermediate: Intermediate,
    ) { }
    renderValue: string;
    @Input() value: string | number;
    @Input() rowData: any;
    @Output() save: EventEmitter<any> = new EventEmitter()
    ngOnInit() {
        // console.debug("aca esta el render despues")
        // console.debug(this.intermediate.Professor)
        this.renderValue = this.intermediate.Professor[this.value];
    }
    ngAfterViewChecked() {
        this.renderValue = this.intermediate.Professor[this.value];
    }
    onClick() {
        this.save.emit(this.rowData);
    }
}
// FIN COMPONENTE PARA EL NG SMART TABLE USUARIO






// INICIO COMPONENTE PARA EL NG SMART TABLE dateChanged
@Component({
    selector: 'button-view-dateChanged',
    template: `
  <b *ngIf="renderValue=='Si'"  style='color: red;'>  {{renderValue}}</b>
  <b *ngIf="renderValue=='No'"   >  {{renderValue}}</b>
      `
})
export class ButtonViewdDateChanged implements ViewCell, OnInit, AfterViewChecked {
    constructor(

    ) { }
    renderValue: string;
    @Input() value: string | number;
    @Input() rowData: any;
    @Output() save: EventEmitter<any> = new EventEmitter()
    ngOnInit() {
        if (this.value!=null || this.value!=undefined) {
            if (this.value.toString() == "true" || this.value.toString() == "1")
                this.renderValue = "Si"
            if (this.value.toString() == "false" || this.value.toString() == "0")
                this.renderValue = "No"
        }
    }
    ngAfterViewChecked() {
        this.renderValue = String(this.value);
    }

}
// FIN COMPONENTE PARA EL NG SMART TABLE  ISWEEKLY

