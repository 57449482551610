import { schoolToLoad } from "./environment.common";

export const environment = {
  production: false,
  baseUrl: 'https://test-default-dot-cedoc-360c.ue.r.appspot.com/api',

  ...schoolToLoad(),

  principalAppName: 'cedoc',
};
