import { Component, Inject, OnInit } from '@angular/core';

import {
  CalendarEvent,
  CalendarEventAction,
  CalendarView,
  DAYS_OF_WEEK
} from 'angular-calendar';
import { Subject } from 'rxjs';
import { colors } from './colors';

import { SharedService } from 'src/app/services/shared.service';
import { ScheduleService } from 'src/app/services/schedule.service';
import * as moment from 'moment'
import { take } from 'rxjs/operators';
import { CourseService } from 'src/app/services/course.service';
import { DOCUMENT } from '@angular/common';
import { environment } from 'src/environments/environment';


@Component( {
  selector: 'student-calendar',
  templateUrl: 'student-calendar.component.html',
  styleUrls: [ './student-calendar.component.css', './student-calendar.component.sass' ],
} )
export class StudentCalendarComponent implements OnInit {

  PLATFORM_NAME = environment.principalAppName;
  constructor (
    public sharedService: SharedService,
    private _scheduleService: ScheduleService,
    private _courseService: CourseService,
    @Inject( DOCUMENT ) private document: Document
  ) {
    if (this.PLATFORM_NAME == 'cedoc' ) this.excludeDays = this.excludeDays_cedoc;
    else this.excludeDays = this.excludeDays_celic;
  }

  locale: string = 'es';
  weekStartsOn: number = DAYS_OF_WEEK.MONDAY;
  weekendDays: number[] = [ DAYS_OF_WEEK.FRIDAY, DAYS_OF_WEEK.SATURDAY ]
  excludeDays_celic: number[] = [DAYS_OF_WEEK.SATURDAY, DAYS_OF_WEEK.SUNDAY]; // Excluir días de la semana
  excludeDays_cedoc: number[] = [DAYS_OF_WEEK.SUNDAY]; // Excluir días de la semana
  excludeDays: number[] = []; // Excluir días de la semana
  dayStartHour = 6; // Configuración del horario de inicio del día
  dayEndHour = 21; // Configuración del horario de final del día
  actions: CalendarEventAction[] = [
    {
      label: '<i class="fa  fa-trash"></i>',
      a11yLabel: 'Delete',
      onClick: ( { event }: { event: CalendarEvent } ): void => {
        this.events = this.events.filter( ( iEvent ) => iEvent !== event );
      },
    },
  ];
  showViews = {
    calendar: false,
    events: false
  }
  CalendarView = CalendarView;
  view = CalendarView.Week;
  viewDate = new Date();
  events: CalendarEvent[] = [];
  activeDayIsOpen = true;
  refresh = new Subject<void>();

  //

  ngOnInit(): void {
    this.getActiveUserCourses();
  }

  getActiveUserCourses() {

    let filter = {
      where: {
        and: [ { IsAcepted: true } ]
      },
    }
    this._courseService.myUserCourses( JSON.stringify( filter ) ).pipe( take( 1 ) ).subscribe( data => {
      if ( data.length > 0 ) {
        console.log( "data", data )
        let courseGroupsID = data.map( x => x.CourseGroupID )
        let filterSubjectGroups = {
          where: {
            and: [
              { CourseGroupID: { inq: courseGroupsID } }
            ]
          },
          include: [ "scheduleSubjects", "userapp", "subjects", "coursesgroup" ]
        }

        this._scheduleService.getSubjectGroups( JSON.stringify( filterSubjectGroups ) ).pipe( take( 1 ) ).subscribe( subjectGroups => {
          console.log( "subjectGroups", subjectGroups )
          subjectGroups.forEach( subjectGroup => {
            subjectGroup.scheduleSubjects.forEach( ( scheduleSubject, index ) => {
              let CompleteName = `${ subjectGroup.userapp.LastName1 ? subjectGroup.userapp.LastName1 : '' }  ${ subjectGroup.userapp.LastName2 ? subjectGroup.userapp.LastName2 : '' }  ${ subjectGroup.userapp.Name1 ? subjectGroup.userapp.Name1 : '' } ${ subjectGroup.userapp.Name2 ? subjectGroup.userapp.Name2 : '' } `

              this.events.push( {
                title: `${ subjectGroup.subjects.NameSubject } - ${ subjectGroup.coursesgroup.name }  `,
                color: colors[ index % colors.length ],
                start: moment( scheduleSubject.HourStart ).toDate(),
                end: moment( scheduleSubject.HourEnd ).toDate(),
                resizable: {
                  beforeStart: false,
                  afterEnd: false,
                },
                draggable: false,

                meta: {
                  scheduleSubjectID: scheduleSubject.id,
                  subjectGroupID: subjectGroup.id,
                  subject: subjectGroup.subjects,
                  courseGroup: subjectGroup.coursesgroup,
                  user: subjectGroup.userapp,
                },
              } )

            } )
          } )
        } )
      }
      this.showViews.calendar = true
    } )
  }


  // Funciones para calendar

  closeOpenMonthViewDay() {
    //
  }
  setView( stateView ) {
    //
  }


  /**
   * prints current page
   */
  printPage() {
    window.print();
  }
}
