import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { EmptyRouteComponent } from './empty-route/empty-route.component';
import { HttpClientModule } from '@angular/common/http';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { ScheduleComponent } from './components/schedule/schedule.component';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { ButtonViewCourseGroups, ButtonViewdDateChanged, ButtonViewdPhysicalTest, ButtonViewProfessors } from './components/shared/renders-smartTables';

import { DragAndDropModule } from 'angular-draggable-droppable';

import { CalendarModule, DateAdapter , MOMENT} from 'angular-calendar';
import { CalendarComponent } from './components/shared/calendar/calendar.component';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';

import * as moment from 'moment';
import { registerLocaleData } from '@angular/common';

import locales from '@angular/common/locales/es';
import { FullScheduleComponent } from './components/full-schedule/full-schedule.component';
import { FilterBarComponent } from './components/shared/filter-bar/filter-bar.component';
import { MainCalendarComponent } from './components/shared/calendar-main/calendar-main.component';
import { StudentCalendarComponent } from './components/student-calendar/student-calendar.component';
import { ProfessorCalendarComponent } from './components/professor-calendar/professor-calendar.component';
import { AcademicCalendarComponent } from './components/academic-calendar/academic-calendar.component';
import { OrderByPipe } from './pipes/order-by.pipe';
registerLocaleData(locales)

@NgModule({
  declarations: [
    AppComponent,
    EmptyRouteComponent,
    ScheduleComponent,
    FullScheduleComponent,
    FilterBarComponent,

    StudentCalendarComponent,
    ProfessorCalendarComponent,
    AcademicCalendarComponent,
    CalendarComponent,
    MainCalendarComponent,

    ButtonViewProfessors,
    ButtonViewCourseGroups,
    ButtonViewdDateChanged,
    ButtonViewdPhysicalTest,
    OrderByPipe
  ],
  entryComponents:[  ButtonViewProfessors,
    ButtonViewCourseGroups,
    ButtonViewdDateChanged,
    ButtonViewdPhysicalTest],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    NgSelectModule,
    FormsModule,
    Ng2SmartTableModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),
    DragAndDropModule,
  ],
  bootstrap: [AppComponent],
  schemas:[CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
