import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { Observable } from 'rxjs';
import { Category } from '../models/category';
import { Modality } from '../models/modality';
import { Course } from '../models/course';
import { CourseOffer } from '../models/courseOffer';
import { OferState } from '../models/oferState';
import { School } from '../models/school';
import { take } from 'rxjs/operators';
import { Force } from '../models/force';
import { Range } from '../models/range';
import { UserCourse } from '../models/userCourse';
import { User } from '../models/user';
import { CourseGroup } from '../models/courseGroup';


@Injectable({
  providedIn: 'root'
})
export class CourseService {

  constructor(
    private _httpService: HttpService
  ) { }

  getSchoolsAndChildren(schoolID: number): Observable<School> {
    let url: string = `/Schools/${schoolID}?filter={"include":{"childrenSchools":"childrenSchools"}}`
    return this._httpService.httpGet(url).pipe(take(1));
  }

  getCategories(): Observable<Category[]> {
    let url: string = `/Categories?filter={"include":["TypeCourses"]}`
    return this._httpService.httpGet(url).pipe(take(1));
  }

  getDepartments(): Observable<any[]> {
    let url: string = `/Departaments`
    return this._httpService.httpGet(url).pipe(take(1));
  }

  getTypeTrainings(): Observable<any[]> {
    let url: string = `/TypeTrainings`
    return this._httpService.httpGet(url).pipe(take(1));
  }

  getRanges(filter?: string): Observable<Range[]> {
    let url: string = `/Ranges`
    if (filter)
      url = `/Ranges?filter=${filter}`
    return this._httpService.httpGet(url).pipe(take(1));
  }
  getForces(filter?: string): Observable<Force[]> {
    let url: string = `/Forces`
    if (filter)
      url = `/Forces?filter=${filter}`
    return this._httpService.httpGet(url).pipe(take(1));
  }

  getModalities(): Observable<Modality[]> {
    let url: string = `/Modalities`
    return this._httpService.httpGet(url).pipe(take(1));
  }

  getOferStates(): Observable<OferState[]> {
    let url: string = `/OferStates`
    return this._httpService.httpGet(url).pipe(take(1));
  }

  getCourses(filter?: string): Observable<Course[]> {

    let url: string = `/Courses`
    if (!!filter)
      url = url + "?filter=" + filter

    return this._httpService.httpGet(url).pipe(take(1));
  }

  getCoursesOferById(courseOferID: number, filter?: string): Observable<CourseOffer> {
    let url: string = `/CoursesOfers/${courseOferID}`
    if (!!filter)
      url = url + "?filter=" + filter

    return this._httpService.httpGet(url).pipe(take(1));
  }

  getCoursesOfers(filter?: string): Observable<CourseOffer[]> {
    let url: string = `/CoursesOfers`
    if (!!filter)
      url = url + "?filter=" + filter

    return this._httpService.httpGet(url).pipe(take(1));
  }
  getCourseGroups(filter?: string): Observable<CourseGroup[]> {
    let url: string = `/courseGroups`
    if (!!filter)
      url = url + "?filter=" + filter
    return this._httpService.httpGet(url).pipe(take(1));
  }


  myUserCourses(filter?: string): Observable<UserCourse[]> {
    let url: string = `/userapps/me/userCourses`
    if (!!filter)
      url = url + "?filter=" + filter
    return this._httpService.httpGet(url).pipe(take(1));
  }

  getMyInfo(filter?: string): Observable<User> {
    let url: string = `/userapps/me`
    if (!!filter)
      url = url + "?filter=" + filter
    return this._httpService.httpGet(url).pipe(take(1));
  }


}
