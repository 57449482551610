import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';

import {
  CalendarEvent,
  CalendarEventAction,
  CalendarEventTimesChangedEvent,
  CalendarView,
  DAYS_OF_WEEK
} from 'angular-calendar';
import { forkJoin, from, Subject } from 'rxjs';
import { colors } from './colors';

import { SharedService } from 'src/app/services/shared.service';
import { ScheduleService } from 'src/app/services/schedule.service';
import { CourseGroup } from 'src/app/models/courseGroup';
import { AcademicArea } from 'src/app/models/academicArea';
import * as moment from 'moment'
import { SubjectGroup } from 'src/app/models/subjectGroup';
import { take } from 'rxjs/operators';
import { ScheduleSubject } from 'src/app/models/scheduleSubject';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'main-calendar-schedule',
  templateUrl: 'calendar-main.component.html',
  styleUrls: ['./calendar-main.component.css', './calendar-main.component.sass'],
})
export class MainCalendarComponent implements OnInit, OnChanges {

  @Input() public courseGroupID: number;
  @Input() public subjectGroups: SubjectGroup[];
  @Input() public schoolID: number;
  @Input() public subjectID: number;
  @Input() public courseGroups: CourseGroup[];

  @Output() outputEvent = new EventEmitter();  // { typeEvent:  <edit|closeWindow|>  , data: Object      }




  locale: string = 'es';

  weekStartsOn: number = DAYS_OF_WEEK.MONDAY;

  weekendDays: number[] = [DAYS_OF_WEEK.FRIDAY, DAYS_OF_WEEK.SATURDAY];
  excludeDays_celic: number[] = [DAYS_OF_WEEK.SATURDAY, DAYS_OF_WEEK.SUNDAY]; // Excluir días de la semana
  excludeDays_cedoc: number[] = [DAYS_OF_WEEK.SUNDAY]; // Excluir días de la semana
  excludeDays: number[] = []; // Excluir días de la semana
  dayStartHour = 6; // Configuración del horario de inicio del día
  dayEndHour = 21; // Configuración del horario de final del día

  actions: CalendarEventAction[] = [

    {
      label: '<i class="fa  fa-trash"></i>',
      a11yLabel: 'Delete',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.handleEvent('Deleted', event);
        this.events = this.events.filter((iEvent) => iEvent !== event);
      },
    },
  ];

  showViews = {
    calendar: false,
    events: false
  }

  CalendarView = CalendarView;
  view = CalendarView.Week;
  viewDate = new Date();
  externalEvents: CalendarEvent[] = [
  ];

  events: CalendarEvent[] = [];

  activeDayIsOpen = true;

  refresh = new Subject<void>();

  PLATFORM_NAME = environment.principalAppName;
  constructor(
    public sharedService: SharedService,
    private _scheduleService: ScheduleService,
  ) {
    if (this.PLATFORM_NAME == 'cedoc' ) this.excludeDays = this.excludeDays_cedoc;
    else this.excludeDays = this.excludeDays_celic;
  }






  ngOnChanges(changes: SimpleChanges) {
    if (changes['courseGroupID']) {
      this.courseGroupID = changes['courseGroupID'].currentValue
      this.loadEvents()
    }

    if (changes['subjectGroups'] || changes['subjectID']) {
      if (changes['subjectGroups']) this.subjectGroups = changes['subjectGroups'].currentValue
      if (changes['subjectID']) this.subjectID = changes['subjectID'].currentValue
      this.loadSubjects()
      this.loadEvents()
    }
    if (changes['schoolID']) {
      this.schoolID = changes['schoolID'].currentValue
    }
    if (changes['courseGroups']) {
      this.courseGroups = changes['courseGroups'].currentValue
    }

  }

  ngOnInit(): void {

  }


  handleEvent(action: string, event: CalendarEvent): void {
    if (action === "Deleted") {
      if (event.meta.scheduleSubjectID) {
        this._scheduleService.deleteScheduleSubjectById(event.meta.scheduleSubjectID).pipe(take(1)).subscribe(deleted => {
          this.events = this.events.filter((iEvent) => iEvent !== event);

          this.sharedService.swalSuccess("Eliminado", "Evento Eliminado")
        })
      }
      else {
        this.events = this.events.filter((iEvent) => iEvent !== event);

      }
    }
  }


  loadEvents() {

    if (this.courseGroupID) {
      this.showViews.calendar = false
      //let weekNumber = Number(moment().format("W"))
      // let firstDay = moment().week(weekNumber).startOf('week')
      // let endDay = moment().week(weekNumber + 1).endOf('week')
      this.events = []
      let filterSubjectGoups =
      {
        where: {
          and: []
        },
        include: ["scheduleSubjects", "userapp", "subjects", "coursesgroup"]
      }
      if (this.courseGroupID == -1 && this.courseGroups.length > 0)
        filterSubjectGoups.where.and.push({ CourseGroupID: { inq: [this.courseGroups.filter(x => x.id > 0).map(y => y.id)] } })
      else if (this.courseGroupID > 0 && this.courseGroups.length > 0)
        filterSubjectGoups.where.and.push({ CourseGroupID: this.courseGroupID })


      this._scheduleService.getSubjectGroups(JSON.stringify(filterSubjectGoups)).pipe(take(1)).subscribe(data => {
        this.events = []


        for (let index = 0; index < data.length; index++) {
          let subjectGroup = data[index];
          for (let index2 = 0; index2 < subjectGroup.scheduleSubjects.length; index2++) {
            let schedule = subjectGroup.scheduleSubjects[index2];
            let CompleteName = `${subjectGroup.userapp.LastName1 ? subjectGroup.userapp.LastName1 : ''}  ${subjectGroup.userapp.LastName2 ? subjectGroup.userapp.LastName2 : ''}  ${subjectGroup.userapp.Name1 ? subjectGroup.userapp.Name1 : ''} ${subjectGroup.userapp.Name2 ? subjectGroup.userapp.Name2 : ''} `
            this.events.push({
              title: `${subjectGroup.subjects.NameSubject} - ${subjectGroup.coursesgroup.name}  `,
              color: colors[index],
              start: moment(schedule.HourStart).toDate(),
              end: moment(schedule.HourEnd).toDate(),
              resizable: {
                beforeStart: true,
                afterEnd: true,
              },
              draggable: true,
              actions: this.actions,
              meta: {
                scheduleSubjectID: schedule.id,
                subjectGroupID: subjectGroup.id,
                subject: subjectGroup.subjects,
                courseGroup: subjectGroup.coursesgroup,
                user: subjectGroup.userapp,
              },
            })
          }
        }


        // data.forEach( (subjectGroup, index) => {
        //   subjectGroup.scheduleSubjects.forEach( schedule => {
        //     let CompleteName = `${subjectGroup.userapp.LastName1 ? subjectGroup.userapp.LastName1 : ''}  ${subjectGroup.userapp.LastName2 ? subjectGroup.userapp.LastName2 : ''}  ${subjectGroup.userapp.Name1 ? subjectGroup.userapp.Name1 : ''} ${subjectGroup.userapp.Name2 ? subjectGroup.userapp.Name2 : ''} `
        //     this.events.push({
        //       title: `${subjectGroup.subjects.NameSubject} - ${CompleteName} `,
        //       color: colors[index],
        //       start: moment(schedule.HourStart).add(5, "hours").toDate(),
        //       end: moment(schedule.HourEnd).add(5, "hours").toDate(),
        //       resizable: {
        //         beforeStart: true, // this allows you to configure the sides the event is resizable from
        //         afterEnd: true,
        //       },
        //       draggable: true,
        //       actions: this.actions,
        //       meta: {
        //         scheduleSubjectID: schedule.id,
        //         subjectGroupID: subjectGroup.id
        //       },
        //     })

        //   });

        // });

        this.showViews.calendar = true
      })

    }


  }




  loadSubjects() {

    if (this.subjectGroups != null || this.subjectGroups != undefined) {
      if (this.subjectGroups.length == 0)
        return
      this.externalEvents = []
      this.subjectGroups.filter(x => x.SubjectID == this.subjectID).forEach((subjectGroup, index) => {
        console.log(subjectGroup)
        for (let tempIndex = 0; tempIndex < 5; tempIndex++) {
          this.externalEvents.push({
            title: `${subjectGroup.subjects.NameSubject} - ${subjectGroup?.coursesgroup?.name} `,
            color: colors[index],
            start: new Date(),
            actions: this.actions,
            draggable: true,
            resizable: {
              beforeStart: true,
              afterEnd: true,
            },
            meta: {
              subjectGroupID: subjectGroup.id,
              subject: subjectGroup.subjects,
              user: subjectGroup.userapp,
              courseGroup: subjectGroup.coursesgroup,
              scheduleSubjectID: null
            },
          })

        }

      })
      this.showViews.events = true

    }
    else {
      this.showViews.events = false
    }

  }


  eventDropped({
    event,
    newStart,
    newEnd,
    allDay,
  }: CalendarEventTimesChangedEvent): void {
    const externalIndex = this.externalEvents.indexOf(event);
    event["isModified"] = true
    if (typeof allDay !== 'undefined') {
      event.allDay = allDay;
    }
    if (externalIndex > -1) {
      this.externalEvents.splice(externalIndex, 1);
      this.events.push(event);
    }
    event.start = newStart;
    if (newEnd) {
      event.end = newEnd;
    }
    if (this.view === 'month') {
      this.viewDate = newStart;
      this.activeDayIsOpen = true;
    }
    this.events = [...this.events];
  }

  externalDrop(event: CalendarEvent) {
    if (this.externalEvents.indexOf(event) === -1) {
      this.events = this.events.filter((iEvent) => iEvent !== event);
      this.externalEvents.push(event);
    }
  }

  opcionSeleccionada = 1;
  saveCalendar() {
    this.showViews.calendar = false
    let eventsToSave = this.events.filter(x => x['isModified'])
    console.log(eventsToSave)
    let weeksToRepeat = this.opcionSeleccionada;

    from(eventsToSave).subscribe(event => {

      for (let i = 0; i < weeksToRepeat; i++) {
        let currentEventDate = moment(event.start).add(i * 7, 'days');

        let data: ScheduleSubject = {
          id: null,
          UserID: this.subjectGroups.find(x => x.id == event.meta.subjectGroupID).UserID,
          SchoolID: this.schoolID,
          SubjectGroupID: event.meta.subjectGroupID,
          // Day: moment(event.start).format("YYYY-MM-DD"),
          Day: currentEventDate.format("YYYY-MM-DD"),
          // HourStart: String(event.start),
          HourStart: String(currentEventDate),
          // HourEnd: event.end ? String(event.end) : moment(event.start).add(1, "hour").format("YYYY-MM-DD hh:mm:ss")
          HourEnd: String(moment(currentEventDate).add(1, "hour"))
        }
        event.meta.scheduleSubjectID ? data['id'] = event.meta.scheduleSubjectID : '';
        forkJoin(
          [this._scheduleService.patchScheduleSubjects(data).pipe(take(1))]
        ).subscribe(
          data => { },
          err => { console.log('Error al traer guardar schedulesubject  ', event); },
          () => {
            console.log('forkJoin finally');
          }
        );
      }


    }, err => { console.log('Error recorriendo datos') }
    , () => {
      console.log('from finally');
      //window.location.reload();
      this.loadEvents();
    })
  }


  closeOpenMonthViewDay() {

  }

  setView(event) {

  }

  shoInfoEvent(event) {
    console.log(event)
  }


  clickEventTemplate(event) {
    console.log(event)
  }


}
