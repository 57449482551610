import { Component, OnInit } from '@angular/core';

import {
  CalendarEvent,
  CalendarEventAction,
  CalendarView,
  DAYS_OF_WEEK
} from 'angular-calendar';
import { Subject } from 'rxjs';
import { colors } from './colors';

import { SharedService } from 'src/app/services/shared.service';
import { ScheduleService } from 'src/app/services/schedule.service';
import * as moment from 'moment'
import { take } from 'rxjs/operators';
import { CourseService } from 'src/app/services/course.service';
import { environment } from 'src/environments/environment';


@Component( {
  selector: 'professor-calendar',
  templateUrl: 'professor-calendar.component.html',
  styleUrls: [ './professor-calendar.component.css', './professor-calendar.component.sass' ],
} )
export class ProfessorCalendarComponent implements OnInit {

  locale: string = 'es';
  weekStartsOn: number = DAYS_OF_WEEK.MONDAY;
  weekendDays: number[] = [ DAYS_OF_WEEK.FRIDAY, DAYS_OF_WEEK.SATURDAY ];
  excludeDays_celic: number[] = [DAYS_OF_WEEK.SATURDAY, DAYS_OF_WEEK.SUNDAY]; // Excluir días de la semana
  excludeDays_cedoc: number[] = [DAYS_OF_WEEK.SUNDAY]; // Excluir días de la semana
  excludeDays: number[] = []; // Excluir días de la semana
  dayStartHour = 6; // Configuración del horario de inicio del día
  dayEndHour = 21; // Configuración del horario de final del día
  actions: CalendarEventAction[] = [
    {
      label: '<i class="fa  fa-trash"></i>',
      a11yLabel: 'Delete',
      onClick: ( { event }: { event: CalendarEvent } ): void => {
        this.events = this.events.filter( ( iEvent ) => iEvent !== event );
      },
    },
  ];
  showViews = {
    calendar: false,
    events: false
  }
  CalendarView = CalendarView;
  view = CalendarView.Week;
  viewDate = new Date();
  events: CalendarEvent[] = [];
  activeDayIsOpen = true;
  refresh = new Subject<void>();
  PLATFORM_NAME = environment.principalAppName;
  constructor (
    public sharedService: SharedService,
    private _scheduleService: ScheduleService,
    private _courseService: CourseService,
  ) {
    if (this.PLATFORM_NAME == 'cedoc' ) this.excludeDays = this.excludeDays_cedoc;
    else this.excludeDays = this.excludeDays_celic;
  }





  ngOnInit(): void {

    this.getMySubjects();

  }

  getMySubjects() {

    this._courseService.getMyInfo().pipe( take( 1 ) ).subscribe( data => {
      let startMoment = moment().subtract( 1, 'days' ).startOf( "year" )
      let endMoment = moment().add( 1, 'days' ).endOf( "year" )
      let filterSubjectGroups = {
        where: {
          and: [
            { UserID: data.id },
            { DateStart: { between: [ startMoment.toISOString(), endMoment.toISOString() ] } }
          ]
        },
        include: [ "scheduleSubjects", "userapp", "subjects", "coursesgroup" ]
      }

      this._scheduleService.getSubjectGroups( JSON.stringify( filterSubjectGroups ) ).pipe( take( 1 ) ).subscribe( subjectGroups => {
        subjectGroups.forEach( subjectGroup => {
          subjectGroup.scheduleSubjects.forEach( ( scheduleSubject, index ) => {
            this.events.push( {
              title: `${ subjectGroup.subjects.NameSubject } - ${ subjectGroup.coursesgroup.name }  `,
              color: colors[ index % colors.length ],
              start: moment( scheduleSubject.HourStart ).toDate(),
              end: moment( scheduleSubject.HourEnd ).toDate(),
              resizable: {
                beforeStart: false,
                afterEnd: false,
              },
              draggable: false,
              meta: {
                scheduleSubjectID: scheduleSubject.id,
                subjectGroupID: subjectGroup.id,
                subject: subjectGroup.subjects,
                courseGroup: subjectGroup.coursesgroup,
                user: subjectGroup.userapp,
              },
            } )
          } )
        } )
      } )
      this.showViews.calendar = true

    } )
  }


  // Funciones para calendar
  closeOpenMonthViewDay() {
    //
  }

  setView( stateView ) {
    //
  }

  /**
   * prints current page
   */
  printPage() {
    window.print();
  }
}
