import { Component,Input, OnInit, SimpleChanges } from '@angular/core';
import { forkJoin, Subscription } from 'rxjs';
import { Category } from 'src/app/models/category';
import { Course } from 'src/app/models/course';
import { CourseOffer } from 'src/app/models/courseOffer';
import { Modality } from 'src/app/models/modality';
import { OferState } from 'src/app/models/oferState';
import { School } from 'src/app/models/school';
import { TypeCourse } from 'src/app/models/typecourse';


import { CourseService } from 'src/app/services/course.service';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'directiva-filter-bar',
  templateUrl: './filter-bar.component.html',
  styleUrls: ['./filter-bar.component.css']
})
export class FilterBarComponent implements OnInit {

  @Input()
  mySchool: number
  @Input()
  yearSearch: number

  public viewFilterForm: boolean = false


  public schools: School[] = [];
  public schoolsSelected: School[] = [];



  constructor(
    private courseService: CourseService,
    private sharedService: SharedService
  ) { }

  ngOnInit(): void {
  
  }

  ngOnDestroy() {
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'mySchool': {
            this.getFixedFilters();
          } break;

          case 'yearSearch': {
            this.sharedService.nextyearSchoolFilter({year:this.yearSearch,schools:this.schoolsSelected});
          } break;

        }
      }
    }
  }

  public setSchool(school: School[]) {
    this.schoolsSelected = school
    this.sharedService.nextyearSchoolFilter({year:this.yearSearch,schools:this.schoolsSelected});
  }

  public isSelectedSchool(SchoolID): boolean {
    return !(this.schoolsSelected.findIndex(e => e.id == SchoolID) < 0)
  }

  public getFixedFilters() {
    if (this.mySchool==0)
    return 

    forkJoin([
      this.courseService.getSchoolsAndChildren(this.mySchool)
    ]).subscribe(Val => {
      this.schools = [];

      [this.schools[0]] = Val;

    

      this.schools[0].childrenSchools.forEach(school=>{
        this.schools.push(school)
        this.schools.push(...school.childrenSchools)
      })
      
      
      this.schoolsSelected = [ this.schools[0]];
      this.sharedService.nextyearSchoolFilter({year:this.yearSearch,schools:this.schoolsSelected});
     

    });
  }



}
