import { Injectable, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';


// Modulos Externos
import * as moment from 'moment';
import Swal from 'sweetalert2';
import { take } from 'rxjs/operators';
import { School } from '../models/school';
import { ScheduleService } from './schedule.service';
import { CourseOffer } from '../models/courseOffer';


@Injectable({
  providedIn: 'root'
})
export class SharedService {


  private courseOfferlist = new BehaviorSubject([new CourseOffer()]);
  sharedcourseOfferlist = this.courseOfferlist.asObservable();

  private categoryNameFilter = new BehaviorSubject({ filterName: "", value: "" });
  sharedCategoryNameFilter = this.categoryNameFilter.asObservable();

  private yearSchoolFilter = new BehaviorSubject({ year: 2017, schools: [] });
  sharedyearSchoolFilter = this.yearSchoolFilter.asObservable();



  constructor(
    private _scheduleService: ScheduleService,

  ) {
  }

  /*** Message Emiter */
  nextcourseOfferlist(courseOfferlist: CourseOffer[]) {
    this.courseOfferlist.next(courseOfferlist)
  }

  nextyearSchoolFilter(yearSchoolFilter: { year: number, schools: School[] }) {
    this.yearSchoolFilter.next(yearSchoolFilter)
  }

  nextcategoryNameFilter(categoryNameFilter: { filterName: string, value: string }) {
    this.categoryNameFilter.next(categoryNameFilter)
  }



  /***** FUNCIONES QUE NO CONSULTAN API */

  sleep = (milliseconds) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds))
  }

  getUnique(arr, comp) {
    const unique = arr
      .map(e => e[comp])
      // store the keys of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)
      // eliminate the dead keys & store unique objects
      .filter(e => arr[e]).map(e => arr[e]);
    return unique;
  }

  swalLoading(titleString: string) {
    Swal.fire({
      icon: 'success',
      title: titleString,
      text: 'Este proceso puede tomar tiempo',
      showConfirmButton: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
      onOpen: function () {
        Swal.showLoading();
      }

    });

  }

  swalEndLoad() {
    Swal.fire({
      icon: 'error',
      timer: 5,

    });

  }




  swalSuccess(titleString: string, textString: string) {

    Swal.fire({
      icon: 'success',
      title: titleString,
      text: textString,
      showConfirmButton: true,
      allowEscapeKey: false,
      allowOutsideClick: false,

    })

  }

  swalWarning(titleString: string, textString: string) {

    Swal.fire({
      icon: 'warning',
      title: titleString,
      text: textString,
      showConfirmButton: true,
      allowEscapeKey: false,
      allowOutsideClick: false,

    })

  }


  swalError(titleString: string, textString: string) {

    Swal.fire({
      icon: 'error',
      title: titleString,
      text: textString,
      showConfirmButton: true,
      allowEscapeKey: false,
      allowOutsideClick: false,
    })

  }


  returnDateForm(dateMoment, typeFormat?: string) {

    let format = "YYYY-MM-DD"
    if (typeFormat)
      format = typeFormat

    return moment(dateMoment).add({ days: 1 }).format(format)
  }


  getMyInfo(): Promise<any> {

    return new Promise((resolve, reject) => {
      let myInfo = { myRoles: [], schools: [], isCedoc: false, mySchoolID: 0 }
      let filterRole = `{"include":["role"]}`
      this._scheduleService.getMyRoleMappings(filterRole).pipe(take(1)).subscribe(data => {
        let roles = data.map(x => x.role);
        let schoolsID = data.map(x => x.SchoolID);

        myInfo.mySchoolID = data[0].SchoolID
        myInfo.myRoles = this.getUnique(roles, 'name')
        let filter1 = {
          include: ["parentSchool",
            { relation: 'childrenSchools', scope: { order: 'NameTSchool ASC' } },
          ]
        };
        this._scheduleService.getSchoolInfoByID(schoolsID[0], JSON.stringify(filter1)).pipe(take(1)).subscribe(data => {
          // NIVEL 0 : CEDOC
          // NIVEL 1 : CENTRO DE EDUCACION
          // NIVEL 2 : ESCUELA

          if (data.childrenSchools.length > 0 && (data.parentSchool)) {
            //Si tengo hijos pero el padre es la misma escuela (SOY CEDOC, NIVEL 0) 
            if (schoolsID[0] === data.parentSchool.id) {
              this._scheduleService.getSchoolInfo(JSON.stringify({ include: { childrenSchools: ["childrenSchools"] } })).pipe(take(1)).subscribe(data2 => {
                myInfo.schools = data2
                myInfo.isCedoc = true

                resolve(myInfo)
              })
            } else {
              //Si tengo Hijos  y tengo padre diferente a mi escuela (ESTOY EN EL NIVEL 1)
              myInfo.schools = [data, ...data.childrenSchools]
              myInfo.isCedoc = false
              resolve(myInfo)
            }
          }
          else {
            // Si  no tengo hijos y si tengo padre (ESTOY EN EL NIVEL 2)
            console.log("NIVEL 2")
            myInfo.schools = [data]
            myInfo.isCedoc = false
            resolve(myInfo)
          }
        })
      })
    })

  }



}
