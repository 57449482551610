import { Course } from './course';
import { OferState } from './oferState';
import { School } from './school';
import { UserCourse } from './userCourse';

export class CourseOffer {


   





    public id: number;
    public NameCourseOfer: string;
    public IsActive: boolean;
    public Since: string;
    public Until: string;
    public RealSince: string;
    public RealUntil: string;
    public Price: number;
    public IsSemestralize: boolean;
    public InscriptStart: string;
    public InscriptEnd: string;
    public altitud: number
    public longitud: number;
    public semesterNumber: number;
    public Profiles: Array<any>;

    public CategoryID: number;

    public SchoolID: number;
    public CourseID: number;
    public TypeCourseID: number;
    public departamentID: number;
    public typeTrainingID: number;

    public EditSince?:boolean;
    public EditUntil?:boolean;
    public EditInscriptStart?:boolean;
    public EditIInscriptEnd?:boolean;
    public OferState:number;
    public IsDirectiva: boolean;

      
    public Forces:Array<any>;
    public ForeignPeople: number;
    public EditRealUntil?:boolean;
    public EditRealSince?:boolean;



    // Usado en agunos formularios para hacer calculos automaticos
    public ModalityID?:number;
    public NumberWeeks?:number;
    public TotalPeople?:number;
    public NumberDays?:number;

    // INCLUDES ENPOINT
    public course?: Course;
    public school?: School;
    public TypeCourseOFer?: any;
    public coursegroups?: any;
    public BreakTimes?: any;
    public ClassroomAvailabilitys?: any;
    public schoolApprs?: any;
    public oferState?: OferState;
    public UserCourses?: Array<UserCourse>
    

    // Para graficas
    public totalMatriculados?:number;
    public totalInscritos?:number;
    public totalGraduados?:number;

   

    constructor(a?: any) {
        // this.id= a ? a.id : null;
        // this.NameCourseOfer= a ? a.NameCourseOfer : null;
        // this.IsActive= a ? a.IsActive : null;
        // this.Since= a ? a.Since : null;
        // this.Until= a ? a.Until : null;
        // this.RealSince= a ? a.RealSince : null;
        // this.RealUntil= a ? a.RealUntil : null;
        // this.Price= a ? a.Price : null;
        // this.isSemestralize= a ? a.isSemestralize : null;
        // this.InscriptStart= a ? a.InscriptStart : null;
        // this.InscriptEnd= a ? a.InscriptEnd : null;
        // this.altitud= a ? a.altitud : null;
        // this.longitud= a ? a.longitud : null;
        // this.semesterNumber= a ? a.semesterNumber : null;
        // this.SchoolID= a ? a.SchoolID : null;
        // this.CourseID= a ? a.CourseID : null;
        // this.TypeCourseID= a ? a.TypeCourseID : null;
        // this.departamentID= a ? a.departamentID : null;
        // this.typeTrainingID= a ? a.typeTrainingID : null;
        // this.ModalityID= a ? a.ModalityID : null;

        // this.CategoryID= a ? a.CategoryID : null;
    }


}
