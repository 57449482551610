'use strict';
import { Component, HostListener, OnInit } from '@angular/core';
import { SharedService } from 'src/app/services/shared.service';
import * as moment from 'moment';
import { ScheduleService } from 'src/app/services/schedule.service';
import { take } from 'rxjs/operators';
import { CourseOffer } from 'src/app/models/courseOffer';
import { CourseGroup } from 'src/app/models/courseGroup';
import { AcademicArea } from 'src/app/models/academicArea';
import {
  ButtonViewdDateChanged,
  ButtonViewdPhysicalTest,
  ButtonViewProfessors,
  ButtonViewCourseGroups,
  Intermediate,
} from '../shared/renders-smartTables';
import { LocalDataSource } from 'ng2-smart-table';
import { Subject } from 'src/app/models/subject';
import { forkJoin, from, Subscription } from 'rxjs';
import { SubjectGroup } from 'src/app/models/subjectGroup';
import { UserJobContract } from 'src/app/models/userJobContract';
import { School } from 'src/app/models/school';
import { NavigationStart, Router } from '@angular/router';
import { environment } from 'src/environments/environment';

const async = require('async');

moment.locale('es');

@Component({
  selector: 'full-schedule-home',
  templateUrl: './full-schedule.component.html',
  styleUrls: [
    './full-schedule.component.css',
    './full-schedule.component.sass',
  ],
  providers: [
    ButtonViewProfessors,
    ButtonViewCourseGroups,
    Intermediate,
    ButtonViewdDateChanged,
    ButtonViewdPhysicalTest,
  ],
})
export class FullScheduleComponent implements OnInit {
  public formFilterSchedule = {
    CourseOferID: null,
    SchoolID: null,
    GroupID: null,
    AcademicAreaID: null,
    SubjectID: null,
  };
  public myInfo: {
    myRoles: any[];
    mySchoolID: number;
    schools: School[];
    isCedoc: boolean;
  } = { myRoles: [], mySchoolID: 0, schools: [], isCedoc: false };
  public courseOfers: CourseOffer[] = [];
  public courseGroups: CourseGroup[] = [];
  public academicAreas: AcademicArea[] = [];
  public showTable: boolean = false;
  public source: LocalDataSource;
  public sourceDates: LocalDataSource;

  public subjects: Subject[] = [];
  public subjectGroupsDB: SubjectGroup[] = [];
  public subjectGroupsTable: SubjectGroup[] = [];
  public userJobContracts: UserJobContract[] = [];
  public selectedSemesterNumber: number = 0;
  public subjectSelected: any = {};
  public SCHOOL_NAME: string = environment.SCHOOL_NAME;

  public settingsSmatTable = {
    noDataMessage: 'No hay materias para el área seleccionada',

    edit: {
      editButtonContent: '<i class="fa fa-pencil-square"></i>',
      saveButtonContent: '<i class="fa fa-floppy-o"></i>',
      cancelButtonContent: '<i class="fa fa-window-close"></i>',
      confirmSave: true,
    },
    actions: {
      add: false,
      delete: false,
      edit: true,
      columnTitle: 'Acciones',
    },
    pager: {
      perPage: 20,
    },
    columns: {
      SubjectID: {
        title: 'Id Materia',
        filter: false,
        editable: false,
      },
      NameSubject: {
        title: 'Materia',
        filter: false,
        editable: false,
      },
      NameCourseGroup: {
        title: 'Grupo',
        filter: false,
        editable: false,
      },
      UserID: {
        title: 'Docente',
        filter: false,
        type: 'custom',
        editor: {
          type: 'list',
          config: {
            list: [],
          },
        },
        renderComponent: ButtonViewProfessors,
        onComponentInitFunction(instance) {
          instance.save.subscribe((row) => {
            // this.saveDocenteDetails(row.id)
          });
        },
      },
      // IsPhysicalTest: {
      //   title: 'Materia Prueba Fisica',
      //   filter: false,
      //   editable: true,
      //   type: 'custom',
      //   editor: {
      //     type: 'list',
      //     config: {
      //       list: [{ value: true, title: 'Si' }, { value: false, title: 'No' }],
      //     },
      //   },
      //   renderComponent: ButtonViewdPhysicalTest
      // },
      // IsSemestralize: {
      //   title: 'Inscripción automática',
      //   filter: false,
      //   editable: true,
      //   type: 'custom',
      //   editor: {
      //     type: 'list',
      //     config: {
      //       list: [{ value: false, title: 'Si' }, { value: true, title: 'No' }],
      //     },
      //   },
      //   renderComponent: ButtonViewdPhysicalTest
      // }
    },
  };

  settingsDate = {
    noDataMessage: 'No hay materias para el área seleccionada',

    edit: {
      editButtonContent: '<i class="fa fa-pencil-square"></i>',
      saveButtonContent: '<i class="fa fa-floppy-o"></i>',
      cancelButtonContent: '<i class="fa fa-window-close"></i>',
      confirmSave: true,
    },
    actions: {
      add: false,
      delete: false,
      edit: true,
      columnTitle: 'Acciones',
    },
    pager: {
      perPage: 20,
    },
    columns: {
      NameSubject: {
        title: 'Materia',
        filter: false,
        editable: false,
      },
      CourseGroupID: {
        title: 'Grupo',
        filter: false,
        editable: false,
        type: 'custom',
        renderComponent: ButtonViewCourseGroups,
      },
      DateStart: {
        title: 'Fecha Inicio (AAAA-MM-DD)',
        filter: false,
        editable: true,
      },
      DateEnd: {
        title: 'Fecha Final (AAAA-MM-DD)',
        filter: false,
        editable: true,
      },
      IsDateChanged: {
        title: 'Se han modificado las fechas?',
        filter: false,
        editable: false,
        type: 'custom',
        renderComponent: ButtonViewdDateChanged,
      },
    },
  };

  //Quantity of groups to be created
  public groupsToCreate: number = 0;
  public CEMIL_ICON: any = environment.CEMIL_ICON;
  public EJC_ICON: any = environment.EJC_ICON;
  private subscriptionSchool: Subscription;

  constructor(
    private _scheduleService: ScheduleService,
    public sharedService: SharedService,
    public intermediateDataST: Intermediate,
    private _router: Router
  ) {
    this.source = new LocalDataSource(); // create the source
    this.sourceDates = new LocalDataSource(); // create the source
  }

  ngOnDestroy() {
    this.subscriptionSchool.unsubscribe();
  }

  ngOnInit(): void {
    this.sharedService.getMyInfo().then((data) => {
      this.myInfo = data;
      if (this.myInfo.schools.length > 0) {
        this.formFilterSchedule.SchoolID = this.myInfo.schools[0].id;
        this.getCourseOfers();
      }
    });

    this.subscriptionSchool =
      this.sharedService.sharedyearSchoolFilter.subscribe((data) => {
        if (data.schools.length > 0) {
          this.myInfo.schools = data.schools;
          this.formFilterSchedule.SchoolID = this.myInfo.schools[0].id;
          this.getCourseOfers();
        }
      });
  }

  getSubjectsAndGroups() {
    this.formFilterSchedule.SubjectID = null;
    let courseOferSelected = this.courseOfers.find(
      (x) => x.id == this.formFilterSchedule.CourseOferID
    );

    const filterSubjects: any = {
      order: 'NameSubject ASC',
      where: {
        and: [{ CourseID: courseOferSelected.CourseID }],
      },
      include: [
        {
          relation: 'Courses',
          scope: { fields: ['IsSemestralize'] },
        },
        {
          relation: 'subjectgroup',
        },
      ],
    };

    if (this.SCHOOL_NAME === 'CELIC') {
      const celicFilters = [
        { AcademicAreaID: this.formFilterSchedule.AcademicAreaID },
        { SemesterNumber: this.filterSubjectsByGrade() },
      ].filter((e) => e.AcademicAreaID !== -1); //If AcademicAreaID === -1, filter all

      filterSubjects.where.and.push(...celicFilters);

     /*  const academicAreaIDs = this.subjects
        .filter((subject) => subject.AcademicAreaID !== null)
        .map((subject) => subject.AcademicAreaID);

      this.academicAreas = this.academicAreas.filter((area) =>
        academicAreaIDs.includes(area.id)
      ); */
    }

    let filterCourseGroups = {
      where: {
        order: 'name ASC',
        CourseOferID: this.formFilterSchedule.CourseOferID,
      },
    };

    const today = new Date();
    const nextWeek = new Date(today.setDate(today.getDate() + 5));
    let filterContracts = {
      where: {
        and: [
          { SchoolID: this.formFilterSchedule.SchoolID },
          { StartContract: { lt: nextWeek.toISOString() } },
          { EndContract: { gt: nextWeek.toISOString() } },
        ],
      },
      include: ['Userapps', 'TypeContracts'],
    };

    forkJoin([
      this._scheduleService
        .getSubjects(JSON.stringify(filterSubjects))
        .pipe(take(1)),
      this._scheduleService
        .getCourseGroups(JSON.stringify(filterCourseGroups))
        .pipe(take(1)),
      this._scheduleService
        .getUserJobsContract(JSON.stringify(filterContracts))
        .pipe(take(1)),
    ]).subscribe(
      (data) => {
        [this.subjects, this.courseGroups, this.userJobContracts] = data;

        if (this.courseGroups.length == 0)
          this.courseGroups = [
            {
              id: null,
              name: 'No existen grupos para la  oferta seleccionada',
            },
          ];
        else {
          this.courseGroups.unshift({ id: -1, name: 'Todos Los grupos' });
          this.formFilterSchedule.GroupID = -1;
        }
      },
      (err) => {
        console.log('Error al traer todas los recursos  ');
      },
      () => {
        this.getSubjectGroups();
      }
    );
  }

  private filterSubjectsByGrade(): number {
    //Filter by grades
    const selectedCourseOffer = this.courseOfers.find(
      (e) => e.id === this.formFilterSchedule.CourseOferID
    );
    if (selectedCourseOffer) {
      const text = selectedCourseOffer.NameCourseOfer.toLowerCase();

      const courseRegex = /(prejardín|jardín|transición)/i;
      const gradeRegex = /grado\s*(\d+)/i;

      const courseResult = courseRegex.exec(text);

      let courseIndex = 0;
      if (courseResult && courseResult.length > 1) {
        const courseName = courseResult[1].toLowerCase();
        console.log(courseName);
        switch (courseName) {
          case 'prejardín':
            courseIndex = 1;
            break;
          case 'jardín':
            courseIndex = 2;
            break;
          case 'transición':
            courseIndex = 3;
            break;
          default:
            break;
        }

        return courseIndex;
      }

      const gradeResult = gradeRegex.exec(text);
      if (gradeResult && gradeResult.length > 1)
        courseIndex = parseInt(gradeResult[1]);

      return courseIndex;
    }
  }

  getSubjectGroups() {
    this.subjectGroupsDB = [];
    let filterSubjectGroups = {
      where: {
        and: [
          {
            CourseGroupID: {
              inq: [this.courseGroups.filter((x) => x.id > 0).map((x) => x.id)],
            },
          },
        ],
      },
      include: ['userapp', 'subjects', 'coursesgroup'],
    };

    this._scheduleService
      .getSubjectGroups(JSON.stringify(filterSubjectGroups))
      .pipe(take(1))
      .subscribe((subjectGroups) => {
        this.subjectGroupsDB = [...subjectGroups];

        this.prepareTable();
      });
  }

  getCourseOfers() {
    this.formFilterSchedule.GroupID = null;
    this.formFilterSchedule.CourseOferID = null;
    this.formFilterSchedule.AcademicAreaID = null;
    this._scheduleService
      .getAcademicAreasBySchoolID(
        this.formFilterSchedule.SchoolID,
        JSON.stringify({ order: 'NameArea ASC' })
      )
      .pipe(take(1))
      .subscribe((data) => {
        this.academicAreas = data;
        this.academicAreas.unshift({ id: -1, NameArea: '--> Todas las Áreas' });
      });
    let filter = {
      order: 'NameCourseOfer ASC',
      where: {
        and: [
          { SchoolID: this.formFilterSchedule.SchoolID },
          { IsActive: true },
        ],
      },
    };
    this._scheduleService
      .getCourseOfers(JSON.stringify(filter))
      .pipe(take(1))
      .subscribe((offers) => {
        this.courseOfers = offers;
      });
  }

  prepareTable() {
    //Cargar grupos para el render tabla
    this.sharedService.swalLoading('Cargando Datos...');
    this.showTable = false;
    this.intermediateDataST.Grups = {};
    this.courseGroups.forEach((curseGroup) => {
      Object.assign(this.intermediateDataST.Grups, {
        [curseGroup.id]: curseGroup.name.toUpperCase(),
      });
    });

    //Cargar Docentes para el render tabla
    this.intermediateDataST.Professor = {};
    Object.assign(this.intermediateDataST.Professor, {
      null: 'Asociar Docente',
    });

    let tempProfesor = []; //Profesores habilitados
    let tempProfesorDisabled = []; //Profesores deshabilitados
    let tempProfesorList = []; // Combinacion de profefores habilitados y deshabilitados
    this.userJobContracts.forEach((profesor) => {
      if (profesor.Userapps) {
        let CompleteName = [
          profesor.Userapps?.LastName1,
          profesor.Userapps?.LastName2,
          profesor.Userapps?.Name1,
          profesor.Userapps?.Name2,
        ]
          .filter(Boolean)
          .join(' ');

        let isBetween = moment().isBetween(
          profesor.StartContract,
          profesor.EndContract,
          undefined,
          '[]'
        ); // true
        // Verifico que el contrato no se haya vencido
        if (!isBetween || profesor.isActive == false) {
          CompleteName += ' (NO HABILITADO-ESCOGER OTRO DOCENTE)';
          tempProfesorDisabled.push({
            value: profesor.Userapps.id,
            title: CompleteName.toUpperCase(),
          });
        } else {
          tempProfesor.push({
            value: profesor.Userapps.id,
            title: CompleteName.toUpperCase(),
          });
        }
      }
    });

    // Llenar la lista de profesores
    tempProfesor = this.sharedService.getUnique(tempProfesor, 'title') //Elimino los repetidos
    tempProfesorDisabled = this.sharedService.getUnique(tempProfesorDisabled, 'title') //Elimino los repetidos
    tempProfesorList = tempProfesorList.concat(tempProfesor)
    let tempProfesorDisabledConfirmed = [];
    tempProfesorDisabled.forEach(userContractDisable => {
      let indexUserWithActiveContract = tempProfesorList.findIndex(x => x.value == userContractDisable.value);
      if (indexUserWithActiveContract == -1) tempProfesorDisabledConfirmed.push(userContractDisable);
    });
    tempProfesorList = tempProfesorList.concat(tempProfesorDisabledConfirmed)
    this.settingsSmatTable.columns.UserID.editor.config.list = tempProfesorList.sort(function (a, b) {
      // Organizo de forma ascendente el nombre de los profesores
      return a['title'] == b['title'] ? 0 : a['title'] < b['title'] ? -1 : 1;
    });
    tempProfesorList.forEach(element => {
      Object.assign(this.intermediateDataST.Professor, { [element.value]: element.title })
    })


    // En este punto hay profesores que no estan en la lista
    // bien sea porque el contrato se finalizó, o esta fuera de los rangos de las fechas de buqueda pero se necesita mostarlos
    // El rango de fechas de contratos es del 1 dia del año en curso al ultimo dia el año en curso

    // - Extraigo ID's  de profesores que estan en la lista de subjectGroups ya creados
    // - Extraigo ID's de los profesores que estan en el la lista de profesores
    // - Encuento qué profesores no están en lista de profesores comparado con  subjectgroups  para obtener el/los ID,
    // - Consulto en UserApss la info del profesor
    // - Consultro en contratos ese id si esta disponible o no
    let professorsIDInSubjectGroups = this.subjectGroupsDB.map((x) => x.UserID);
    let professorsIDtempProfesorList = tempProfesorList.map((x) => x.value);
    let diffProffesors = professorsIDInSubjectGroups.filter(
      (x) => !professorsIDtempProfesorList.includes(x)
    );
    if (diffProffesors.length > 0) {
      from(diffProffesors).subscribe(
        (idProfessor) => {
          let filterContratc = {
            where: {
              and: [
                { userID: idProfessor },
                { SchoolID: this.formFilterSchedule.SchoolID },
              ],
            },
            include: ['Userapps'],
          };
          forkJoin([
            this._scheduleService.getUserByID(idProfessor).pipe(take(1)),
            this._scheduleService
              .getUserJobsContract(JSON.stringify(filterContratc))
              .pipe(take(1)),
          ]).subscribe(
            (data) => {
              // Si hay contratos de esa persona buscar el que cumpla con el rango de hoy
              let nowDate = new Date();
              let isEnabled = false;

              let CompleteName = [
                data[0]?.LastName1,
                data[0]?.LastName2,
                data[0]?.Name1,
                data[0]?.Name2,
              ]
                .filter(Boolean)
                .join(' ');

              if (data[1].length > 0) {
                let userContract = data[1].find(
                  (x) =>
                    new Date(x.StartContract) < nowDate &&
                    nowDate > new Date(x.EndContract)
                );
                if (userContract) isEnabled = true;
                else isEnabled = false;
              } else isEnabled = false;
              if (isEnabled) {
                tempProfesorList.push({
                  value: data[0].id,
                  title: CompleteName.toUpperCase(),
                });
                Object.assign(this.intermediateDataST.Professor, {
                  [data[0].id]: CompleteName.toUpperCase(),
                });
              } else {
                CompleteName += ' (NO HABILITADO-ESCOGER OTRO DOCENTE)';
                tempProfesorList.push({
                  value: data[0].id,
                  title: CompleteName.toUpperCase(),
                });
                Object.assign(this.intermediateDataST.Professor, {
                  [data[0].id]: CompleteName.toUpperCase(),
                });
              }
            },
            (err) => {
              console.log('Error al consultar los usuarios  ');
            },
            () => {
              // this.createSmartTable()
              // this.createSmartTableDates()
            }
          );
        },
        (err) => {
          console.log('Error');
        },
        () => {
          // Esperar 20 Segundos mientras se realizan todas las consultas adicionales del contrato de docentes
          //this.sharedService.sleep(diffProffesors.length * 2000).then(() => {
          this.sharedService.sleep(8000).then(() => {
            this.sharedService.swalEndLoad();
          });
        }
      );
    }
    // Si no hay diferencia de docentes, simplemente preparar los dato para mostrar en la tabla
    else {
      this.sharedService.sleep(1000).then(() => {
        this.sharedService.swalEndLoad();
      });
      // this.createSmartTable()
      // this.createSmartTableDates()
    }
  }

  /**
   *  Allows to create a quantity of subject groups if the subject is semestralized
   */
  onCreateGroups() {
    if (this.groupsToCreate === 0) return;

    //Resets the table
    this.subjectGroupsTable = [];
    this.subjectSelected = this.subjects.find(
      (subject) => subject.id == this.formFilterSchedule.SubjectID
    );
    this.createSmartTableDates();

    //Name to distinguish each subject group
    const subjectGroupNames = [];

    for (let i = 0; i < this.groupsToCreate; i++) {
      const groupName = `GRUPO ${String.fromCharCode(65 + i)}`;
      subjectGroupNames.push(groupName);
    }

    for (let i = 0; i < this.groupsToCreate; i++) {
      let tempGroupsData: SubjectGroup = {
        SubjectID: this.subjectSelected.id,
        NameSubject: this.subjectSelected.NameSubject,
        NameSubjectGroup: this.subjectSelected.NameSubject,
        /* CourseGroupID: courseGroup.id, */
        NameCourseGroup: `${this.subjectSelected.NameSubject} - ${subjectGroupNames[i]}`,
        UserID: null,
        IsPhysicalTest: false,
        IsSemestralize: this.courseOfers.find(
          (x) => x.id == this.formFilterSchedule.CourseOferID
        ).IsSemestralize,
        DateStart: this.courseOfers.find(
          (x) => x.id == this.formFilterSchedule.CourseOferID
        ).Since,
        DateEnd: this.courseOfers.find(
          (x) => x.id == this.formFilterSchedule.CourseOferID
        ).Until,
        IsDateChanged: false,
        HPM: this.subjectSelected.HPM,
      };
      // Calculo de los MIDTERMS
      tempGroupsData.MidTerms = 0;
      if (this.subjectSelected.HPM == 0) tempGroupsData.MidTerms = 1;
      else if (this.subjectSelected.HPM >= 1 && this.subjectSelected.HPM <= 10)
        tempGroupsData.MidTerms = 1;
      else if (this.subjectSelected.HPM >= 11 && this.subjectSelected.HPM < 21)
        tempGroupsData.MidTerms = 2;
      else tempGroupsData.MidTerms = 3;

      this.subjectGroupsTable.push(tempGroupsData);
    }
    this.source.load(this.subjectGroupsTable);
    this.showTable = true;
  }

  /*   @HostListener('window:beforeunload', [ '$event' ])
    beforeUnloadHandler(event) {
      if(this.tempGroupsData.some(e=> e.UserID === null)){
        event.preventDefault();
        this.sharedService.swalWarning('Advertencia','Está a punto de abandonar esta página y aún tienes grupos sin guardar, por favor, revisa nuevamente antes de continuar.');
      }
    } */

  /*   @HostListener('window:unload', [ '$event' ])
    unloadHandler(event) {
      console.log(this.tempGroupsData);
      
      if(this.tempGroupsData.some( e=> e.UserID === null ))
        event.preventDefault()
    } */

  createSmartTable() {
    this.subjectGroupsTable = [];
    this.subjectSelected = this.subjects.find(
      (x) => x.id == this.formFilterSchedule.SubjectID
    );

    this.createSmartTableDates();
    this.courseGroups.forEach((courseGroup) => {
      if (courseGroup.id == -1) {
      } else {
        let tempGroupsData: SubjectGroup = {
          SubjectID: this.subjectSelected.id,
          NameSubject: this.subjectSelected.NameSubject,
          NameSubjectGroup: this.subjectSelected.NameSubject,
          CourseGroupID: courseGroup.id,
          NameCourseGroup: courseGroup.name,
          UserID: null,
          IsPhysicalTest: false,
          IsSemestralize: this.courseOfers.find(
            (x) => x.id == this.formFilterSchedule.CourseOferID
          ).IsSemestralize,
          DateStart: this.courseOfers.find(
            (x) => x.id == this.formFilterSchedule.CourseOferID
          ).Since,
          DateEnd: this.courseOfers.find(
            (x) => x.id == this.formFilterSchedule.CourseOferID
          ).Until,
          IsDateChanged: false,
          HPM: this.subjectSelected.HPM,
        };
        // Calculo de los MIDTERMS
        tempGroupsData.MidTerms = 0;
        if (this.subjectSelected.HPM == 0) tempGroupsData.MidTerms = 1;
        else if (
          this.subjectSelected.HPM >= 1 &&
          this.subjectSelected.HPM <= 10
        )
          tempGroupsData.MidTerms = 1;
        else if (
          this.subjectSelected.HPM >= 11 &&
          this.subjectSelected.HPM < 21
        )
          tempGroupsData.MidTerms = 2;
        else tempGroupsData.MidTerms = 3;
        let posSubjectGroup = -1;
        posSubjectGroup = this.subjectGroupsDB.findIndex(
          (x) =>
            x.SubjectID == this.subjectSelected.id &&
            x.CourseGroupID == courseGroup.id
        );
        if (posSubjectGroup >= 0) {
          tempGroupsData['id'] = this.subjectGroupsDB[posSubjectGroup].id;
          tempGroupsData['UserID'] =
            this.subjectGroupsDB[posSubjectGroup].UserID;
          tempGroupsData['NameSubjectGroup'] =
            this.subjectGroupsDB[posSubjectGroup].NameSubjectGroup;
          tempGroupsData['IsPhysicalTest'] =
            this.subjectGroupsDB[posSubjectGroup].IsPhysicalTest;
          tempGroupsData['IsSemestralize'] =
            this.subjectGroupsDB[posSubjectGroup].IsSemestralize;
          tempGroupsData['DateStart'] =
            this.subjectGroupsDB[posSubjectGroup].DateStart;
          tempGroupsData['DateEnd'] =
            this.subjectGroupsDB[posSubjectGroup].DateEnd;
          tempGroupsData['IsDateChanged'] =
            this.subjectGroupsDB[posSubjectGroup].IsDateChanged;
          tempGroupsData['HPM'] = this.subjectGroupsDB[posSubjectGroup].HPM;
          tempGroupsData['subjects'] =
            this.subjectGroupsDB[posSubjectGroup].subjects;
          tempGroupsData['coursesgroup'] =
            this.subjectGroupsDB[posSubjectGroup].coursesgroup;
        }
        this.subjectGroupsTable.push(tempGroupsData);
      }
    });

    if (
      this.subjectSelected.Courses.IsSemestralize &&
      this.subjectGroupsTable.length === 0
    ) {
      return;
    }

    this.source.load(this.subjectGroupsTable);
    this.showTable = true;
    console.log(this.subjectSelected.subjectgroup.length);
  }

  createSmartTableDates() {
    let smartTabledDates = [];
    this.subjectGroupsDB
      .filter((x) => x.SubjectID == this.formFilterSchedule.SubjectID)
      .forEach((subjectGroup) => {
        let tempGroupsData = {
          id: subjectGroup.id,
          NameSubject: this.subjects.find((x) => x.id == subjectGroup.SubjectID)
            .NameSubject,
          SubjectID: this.subjects.find((x) => x.id == subjectGroup.SubjectID)
            .id,
          CourseGroupID: subjectGroup.CourseGroupID,
          DateStart: moment(subjectGroup.DateStart)
            .utc()
            .add(1, 'days')
            .format('YYYY-MM-DD'),
          DateEnd: moment(subjectGroup.DateEnd)
            .utc()
            .add(1, 'days')
            .format('YYYY-MM-DD'),
          IsDateChanged: subjectGroup.IsDateChanged,
        };

        smartTabledDates.push(tempGroupsData);
      });
    this.sourceDates.load(smartTabledDates);
  }

  onEditConfirmDates(event): any {
    if (
      moment(event.newData.DateStart, 'YYYY-MM-DD').isValid() == true &&
      moment(event.newData.DateEnd, 'YYYY-MM-DD').isValid() == true
    ) {
      //SI EL EVENTO YA SE INDICA QUE LA FECHA  SE HA MODIFICADO
      if (event.data.IsDateChanged == true) {
        this.sharedService.swalError(
          'Error al guardar fechas',
          'La fecha para la materia ya ha sido modificada'
        );
        return;
      }
      var timestart = moment(event.newData.DateStart, 'YYYY-MM-DD');
      var timeEnd = moment(event.newData.DateEnd, 'YYYY-MM-DD');
      if (timestart.isSameOrAfter(timeEnd) == true) {
        this.sharedService.swalError(
          'Error al guardar fechas',
          'La fecha final debe ser mayor a la inicial'
        );
        return;
      }
      var Info = {
        id: event.data.id,
        CourseGroupID: event.newData.CourseGroupID,
        SubjectID: event.newData.SubjectID,
        MidTerms: event.newData.MidTerms,
        DateStart: moment(event.newData.DateStart).format('YYYY-MM-DD'),
        DateEnd: moment(event.newData.DateEnd).format('YYYY-MM-DD'),
        IsDateChanged: true,
      };

      this.sharedService.swalLoading('Modificando Fecha');
      // Actualizo el subjectGroup
      this._scheduleService.patchSubjectGroup(Info).subscribe((newData) => {
        event.newData.IsDateChanged = true;

        //Actualizar todos los dateRecords de acuerdo con las nuevasfechas
        let filterDateRecord = {
          where: {
            and: [{ SubjectGroupID: event.data.id }, { IsExtension: false }],
          },
        };
        let filterDateMidTerms = {
          where: {
            and: [{ SchoolID: this.formFilterSchedule.SchoolID }],
          },
        };
        let filterMidTerms = {
          order: 'MidTermNum ASC',
        };

        forkJoin([
          this._scheduleService
            .getDateRecords(JSON.stringify(filterDateRecord))
            .pipe(take(1)),
          this._scheduleService
            .getDateMidTerms(JSON.stringify(filterDateMidTerms))
            .pipe(take(1)),
          this._scheduleService
            .getMidTermsBySubjectGroupID(
              event.data.id,
              JSON.stringify(filterMidTerms)
            )
            .pipe(take(1)),
        ]).subscribe(
          (data) => {
            let daterecords = data[0];
            let dateMids = data[1];
            let midTerms = data[2];
            let dateMidData;
            if (dateMids.length == 1) dateMidData = dateMids[0];
            if (dateMids.length > 1)
              dateMidData = dateMids[dateMids.length - 1];

            // Recorrer cada midterm y actualizar el daterecord
            from(midTerms).subscribe(
              (midTerm) => {
                var daterecord = daterecords.find(
                  (x) => x.MidTermID === midTerm.id
                );
                var sendData = {
                  id: daterecord.id,
                  DateStartRecord: timestart
                    .subtract(dateMidData['NDays'], 'days')
                    .format('YYYY-MM-DD'),
                  DateEndRecord: timeEnd.add(3, 'days').format('YYYY-MM-DD'),
                };
                this._scheduleService
                  .patchDateRecord(sendData)
                  .pipe(take(1))
                  .subscribe((newPatch) => {});
              },
              (error) => {
                console.error('Error recorriendo midterms');
              },
              () => {
                event.confirm.resolve(event.newData);
                this.sharedService.sleep(1000).then(() => {
                  this.sharedService.swalEndLoad();
                  // this.callSchedule();
                  this.getSubjectGroups();
                });
              }
            );

            // FINALIZA -  RECORRER CADA MIDTERM
          },
          (err) => {
            console.log('Error al traer todas los recursos  ');
          },
          () => {}
        );
      });
    }
  }

  onEditConfirm(event): any {
    this.sharedService.swalLoading('Cargando Datos...');
    let courseOferSelected = this.courseOfers.find(
      (x) => x.id == this.formFilterSchedule.CourseOferID
    );

    //valido las fechas
    if (
      moment(event.newData.DateStart, 'YYYY-MM-DD').isValid() == true &&
      moment(event.newData.DateEnd, 'YYYY-MM-DD').isValid() == true
    ) {
      event.newData.NameCourseGroup;
      let newname =
        event.newData.NameSubject +
        ',' +
        event.newData.NameCourseGroup.substr(-8, 8);
      var Info = {
        CourseGroupID: event.newData.CourseGroupID,
        CourseOferID: courseOferSelected.id,
        IsSemestralize: courseOferSelected.IsSemestralize,
        SubjectID: event.newData.SubjectID,
        MidTerms: event.newData.MidTerms,
        NameSubjectGroup: newname,
        DateStart: moment(event.newData.DateStart).format('YYYY-MM-DD'),
        DateEnd: moment(event.newData.DateEnd).format('YYYY-MM-DD'),
        UserID: parseInt(event.newData['UserID']),
        HPM: event.newData.HPM,
        IsPhysicalTest: event.newData.IsPhysicalTest,
        id: null,
      };

      //SI EL DATO VIENE CON ID ES PORQUE YA TIENE SUBJECTGROUP, LO ADJUNTO A Info
      if (event.newData.id) Info['id'] = event.newData.id;
    } else {
      return this.sharedService.swalError(
        'Error interno en fechas',
        'No se pudo encontrar fechas del curso'
      );
    }

    // VALIDO QUE EL DOCENTE QUE SE GUARDO SI ESTE HABILITADO
    let professor = Object.keys(this.intermediateDataST.Professor).find(
      (key) => key == event.newData['UserID']
    );
    if (
      this.intermediateDataST.Professor[professor].includes(
        'NO HABILITADO-ESCOGER OTRO DOCENTE'
      )
    ) {
      return this.sharedService.swalError(
        'Error al asociar docente',
        'No se puede guardar el usuario porque no esta habilitado, por favor seleccione otro'
      );
    }

    // #########################
    //    1 CREO EL SUBJECGROUP
    //##########################

    this._scheduleService
      .patchSubjectGroup(Info)
      .pipe(take(1))
      .subscribe(
        (subjectGroupData) => {
          event.newData.id = subjectGroupData.id;

          // #########################
          //    2. ASOCIO A LOS ESTUDIANTES
          //##########################

          //CONCATENO LOS DATOS SI SE HA CREADO UN NUEVO SUBJECTGROUP
          if (Info.id == null || Info.id == undefined) {
            subjectGroupData.subjects = this.subjects.find(
              (x) => x.id == subjectGroupData.SubjectID
            );
            let tempProfesor =
              this.settingsSmatTable.columns.UserID.editor.config.list.find(
                (x) => x.value == subjectGroupData.UserID
              );
            subjectGroupData.userapp = {
              id: tempProfesor.value,
              Name1: tempProfesor.title,
            };
            subjectGroupData.IsDateChanged = false;
            this.subjectGroupsDB.push({ ...subjectGroupData });
            this.createSmartTable();
            this.createSmartTableDates();
          }

          if (!event.newData.IsSemestralize) {
            event.confirm.resolve(event.newData);
            this.sharedService.swalSuccess(
              'Docente asociado correctamente',
              'Se ha asociado la materia al docente correctamente'
            );
          } else {
            this.sharedService.swalSuccess('Grupo creado correctamente', '');
            this._scheduleService
              .assignUserSubject(
                JSON.stringify({
                  SubjectGroupID: subjectGroupData.id,
                  CourseGroupID: subjectGroupData.CourseGroupID,
                })
              )
              .pipe(take(1))
              .subscribe(
                (ll) => {
                  event.confirm.resolve(event.newData);

                  this.sharedService.swalSuccess(
                    'Docente asociado correctamente y se asigno los estudiantes',
                    'Se ha asociado la materia al docente correctamente'
                  );
                },
                (e) => {
                  this.sharedService.swalError(
                    'Error asociando estudiantes',
                    'No se pudo asociar los estudiantes, error: ' + e
                  );
                }
              );
          }
        },
        (e) => {
          this.sharedService.swalError(
            'Error asociando docente  ',
            'No se pudo asociar el docente, error: ' + e
          );
        }
      );
  }
}
