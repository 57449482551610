'use strict';
import { Component, OnInit } from '@angular/core';
import { SharedService } from 'src/app/services/shared.service';
import * as moment from 'moment';
import { ScheduleService } from 'src/app/services/schedule.service';
import { take } from 'rxjs/operators';
import { CourseOffer } from 'src/app/models/courseOffer';
import { CourseGroup } from 'src/app/models/courseGroup';
import { AcademicArea } from 'src/app/models/academicArea';
import { ButtonViewdDateChanged, ButtonViewdPhysicalTest, ButtonViewProfessors, ButtonViewCourseGroups, Intermediate } from '../shared/renders-smartTables';
import { LocalDataSource } from 'ng2-smart-table';
import { Subject } from 'src/app/models/subject';
import { forkJoin, from } from 'rxjs';
import { SubjectGroup } from 'src/app/models/subjectGroup';
import { UserJobContract } from 'src/app/models/userJobContract';
import { environment } from 'src/environments/environment';

const async = require("async");

moment.locale('es');

@Component({
  selector: 'schedule-home',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.css', './schedule.component.sass'],
  providers: [ButtonViewProfessors,
    ButtonViewCourseGroups,
    Intermediate,
    ButtonViewdDateChanged,
    ButtonViewdPhysicalTest]
})
export class ScheduleComponent implements OnInit {


  public formFilterSchedule = {
    CourseOferID: null,
    SchoolID: null,
    GroupID: null,
    AcademicAreaID: null
  }
  public myInfo
  public courseOfers: CourseOffer[] = []
  public courseGroups: CourseGroup[] = []
  public academicAreas: AcademicArea[] = []
  public showTable: boolean = false;
  public source: LocalDataSource;
  public sourceDates: LocalDataSource;

  public subjects: Subject[] = []
  public subjectGroupsDB: SubjectGroup[] = [];
  public subjectGroupsTable: SubjectGroup[] = [];
  public userJobContracts: UserJobContract[] = [];




  public settingsSmatTable = {
    noDataMessage: "No hay materias para el área seleccionada",

    edit: {
      editButtonContent: '<i class="fa fa-pencil-square"></i>',
      saveButtonContent: '<i class="fa fa-floppy-o"></i>',
      cancelButtonContent: '<i class="fa fa-window-close"></i>',
      confirmSave: true,
    },
    actions: {
      add: false,
      delete: false,
      edit: true,
      columnTitle: "Acciones"
    },
    pager: {
      perPage: 20,
    },
    columns: {
      SubjectID: {
        title: 'Id Materia',
        filter: false,
        editable: false,
      },
      NameSubject: {
        title: 'Materia',
        filter: false,
        editable: false,
      },
      CourseGroupID: {
        title: 'Grupo',
        filter: false,
        editable: false,
        type: 'custom',
        renderComponent: ButtonViewCourseGroups
      },
      UserID: {
        title: 'Docente',
        filter: false,
        type: 'custom',
        editor: {
          type: 'list',
          config: {
            list: [],
          },
        },
        renderComponent: ButtonViewProfessors,
        onComponentInitFunction(instance) {
          instance.save.subscribe(row => {
            // this.saveDocenteDetails(row.id)
          });
        }
      }
      // IsPhysicalTest: {
      //   title: 'Materia Prueba Fisica',
      //   filter: false,
      //   editable: true,
      //   type: 'custom',
      //   editor: {
      //     type: 'list',
      //     config: {
      //       list: [{ value: true, title: 'Si' }, { value: false, title: 'No' }],
      //     },
      //   },
      //   renderComponent: ButtonViewdPhysicalTest
      // }
    }
  };

  settingsDate = {
    noDataMessage: "No hay materias para el área seleccionada",

    edit: {
      editButtonContent: '<i class="fa fa-pencil-square"></i>',
      saveButtonContent: '<i class="fa fa-floppy-o"></i>',
      cancelButtonContent: '<i class="fa fa-window-close"></i>',
      confirmSave: true,
    },
    actions: {
      add: false,
      delete: false,
      edit: true,
      columnTitle: "Acciones"
    },
    pager: {
      perPage: 20,
    },
    columns: {
      NameSubject: {
        title: 'Materia',
        filter: false,
        editable: false,
      },
      CourseGroupID: {
        title: 'Grupo',
        filter: false,
        editable: false,
        type: 'custom',
        renderComponent: ButtonViewCourseGroups
      },
      DateStart: {
        title: 'Fecha Inicio',
        filter: false,
        editable: true,
      },
      DateEnd: {
        title: 'Fecha Final',
        filter: false,
        editable: true,
      },
      IsDateChanged: {
        title: 'Se han modificado las fechas?',
        filter: false,
        editable: false,
        type: 'custom',
        renderComponent: ButtonViewdDateChanged
      },
    }
  };

  public CEMIL_ICON:any = environment.CEMIL_ICON;
  public EJC_ICON:any = environment.EJC_ICON;

  constructor(
    private _scheduleService: ScheduleService,
    public sharedService: SharedService,
    public intermediateDataST: Intermediate,
  ) {

    this.source = new LocalDataSource(); // create the source
    this.sourceDates = new LocalDataSource(); // create the source

  }



  ngOnInit(): void {

    this.sharedService.getMyInfo().then(data => {
      this.myInfo = data
      if (this.myInfo.schools.length > 0) {
        this.formFilterSchedule.SchoolID = this.myInfo.schools[0].id
        this.getCourseOfers()

      }
    })
  }


  getCourseOfers() {
    this.formFilterSchedule.GroupID = null
    this.formFilterSchedule.CourseOferID = null
    this.formFilterSchedule.AcademicAreaID = null
    this._scheduleService.getAcademicAreasBySchoolID(this.formFilterSchedule.SchoolID, JSON.stringify({ order: "NameArea ASC" })).pipe(take(1)).subscribe(data => {
      this.academicAreas = data
      this.academicAreas.unshift({ id: -1, NameArea: "--> Todas las Áreas" })
    })
    let filter = {
      order: "NameCourseOfer ASC",
      where: {
        and: [
          { SchoolID: this.formFilterSchedule.SchoolID },
          { IsActive: true }
        ]
      }
    }
    this._scheduleService.getCourseOfers(JSON.stringify(filter)).pipe(take(1)).subscribe(offers => {
      this.courseOfers = offers
    })
  }

  callGroups() {
    this.formFilterSchedule.GroupID = null
    this.formFilterSchedule.AcademicAreaID = null
    let filterCourseGroups = {
      where: {
        order: "name ASC",
        CourseOferID: this.formFilterSchedule.CourseOferID
      }
    }
    this._scheduleService.getCourseGroups(JSON.stringify(filterCourseGroups)).pipe(take(1)).subscribe(groups => {
      this.courseGroups = groups
      if (groups.length == 0) {
        this.courseGroups = [{ id: null, name: "No existen grupos para la  oferta seleccionada" }]
      }
    })
  }




  callSchedule() {
    if (
      this.formFilterSchedule.CourseOferID == null ||
      this.formFilterSchedule.GroupID == null
    ) {
      return this.sharedService.swalError("Error", "Debe Seleccionar todos los campos de busqueda")

    }
    // 1. Consulto todas las materias del area academica y del curso al que peretenece la oferta
    // 2. Consulto todos los subjectGroup del grupo seleccionado
    // 3. Conulto la lista de profesores
    this.showTable = false;
    let filterSubjects = {
      order: "NameSubject ASC",
      where: {
        and: [
          { AcademicAreaID: this.formFilterSchedule.AcademicAreaID == -1 ? { inq: [this.academicAreas.map(x => x.id)] } : this.formFilterSchedule.AcademicAreaID },
          { CourseID: this.courseOfers.find(x => x.id == this.formFilterSchedule.CourseOferID).CourseID }
        ]
      },
    }
    let filterSubjectGroups = {
      where: {
        and: [
          { CourseGroupID: this.formFilterSchedule.GroupID },
        ]
      },
      include: ["userapp", "subjects"]
    }
    let startDateMoment = moment().utc().startOf("year");
    let endDateMoment = moment().utc().endOf("year");
    let filterContracts = {
      where: {
        and: [
          { SchoolID: this.formFilterSchedule.SchoolID },
          { StartContract: { between: [startDateMoment.toISOString(), endDateMoment.toISOString()] } },
          { EndContract: { between: [startDateMoment.toISOString(), endDateMoment.toISOString()] } }
        ]
      },
      include: ["Userapps", "TypeContracts"]
    }


    forkJoin([
      this._scheduleService.getSubjects(JSON.stringify(filterSubjects)).pipe(take(1)),
      this._scheduleService.getSubjectGroups(JSON.stringify(filterSubjectGroups)).pipe(take(1)),
      this._scheduleService.getUserJobsContract(JSON.stringify(filterContracts)).pipe(take(1)),
    ]
    ).subscribe(
      data => {
        this.subjects = [...data[0]]
        this.subjectGroupsDB = [...data[1]]
        this.userJobContracts = [...data[2]]
      },
      err => { console.log('Error al traer todas los recursos  '); },
      () => { this.prepareTable() }
    );
  }

  prepareTable() {
    //Cargar grupos para el render tabla
    this.showTable = false
    this.intermediateDataST.Grups = {}
    this.courseGroups.forEach(curseGroup => {
      Object.assign(this.intermediateDataST.Grups, { [curseGroup.id]: curseGroup.name.toUpperCase() })
    })

    //Cargar Docentes para el render tabla
    this.intermediateDataST.Professor = {}
    Object.assign(this.intermediateDataST.Professor, { null: 'Asociar Docente' })

    let tempProfesor = [] //Profesores habilitados
    let tempProfesorDisabled = [] //Profesores deshabilitados
    let tempProfesorList = [] // Combinacion de profefores habilitados y deshabilitados
    this.userJobContracts.forEach(profesor => {
      let CompleteName = `${profesor.Userapps.LastName1 ? profesor.Userapps.LastName1 : ''}  ${profesor.Userapps.LastName2 ? profesor.Userapps.LastName2 : ''}  ${profesor.Userapps.Name1 ? profesor.Userapps.Name1 : ''} ${profesor.Userapps.Name2 ? profesor.Userapps.Name2 : ''} `
      let isBetween = moment().isBetween(profesor.StartContract, profesor.EndContract, undefined, "[]"); // true
      // Verifico que el contrato no se haya vencido
      if (!isBetween || profesor.isActive == false) {
        CompleteName += ' (NO HABILITADO-ESCOGER OTRO DOCENTE)'
        tempProfesorDisabled.push({
          value: profesor.Userapps.id,
          title: CompleteName.toUpperCase(),
        })
      }
      else {
        tempProfesor.push({
          value: profesor.Userapps.id,
          title: CompleteName.toUpperCase(),
        })
      }
    });

    // Llenar la lista de profesores
    tempProfesor = this.sharedService.getUnique(tempProfesor, 'title') //Elimino los repetidos
    tempProfesorDisabled = this.sharedService.getUnique(tempProfesorDisabled, 'title') //Elimino los repetidos
    tempProfesorList = tempProfesorList.concat(tempProfesor)
    tempProfesorList = tempProfesorList.concat(tempProfesorDisabled)
    this.settingsSmatTable.columns.UserID.editor.config.list = tempProfesorList.sort(function (a, b) {
      // Organizo de forma ascendente el nombre de los profesores
      return a["title"] == b["title"] ? 0 : a["title"] < b["title"] ? -1 : 1;
    });
    tempProfesorList.forEach(element => {
      Object.assign(this.intermediateDataST.Professor, { [element.value]: element.title })
    })


    // En este punto hay profesores que no estan en la lista 
    // bien sea porque el contrato se finalizó, o esta fuera de los rangos de las fechas de buqueda pero se necesita mostarlos
    // El rango de fechas de contratos es del 1 dia del año en curso al ultimo dia el año en curso

    // - Extraigo ID's  de profesores que estan en la lista de subjectGroups ya creados
    // - Extraigo ID's de los profesores que estan en el la lista de profesores
    // - Encuento qué profesores no están en lista de profesores comparado con  subjectgroups  para obtener el/los ID,
    // - Consulto en UserApss la info del profesor
    // - Consultro en contratos ese id si esta disponible o no
    let professorsIDInSubjectGroups = this.subjectGroupsDB.map(x => x.UserID)
    let professorsIDtempProfesorList = tempProfesorList.map(x => x.value)
    let diffProffesors = professorsIDInSubjectGroups.filter(x => !professorsIDtempProfesorList.includes(x))
    if (diffProffesors.length > 0) {
      from(diffProffesors).subscribe((idProfessor) => {
        let filterContratc = {
          where: {
            and: [
              { userID: idProfessor },
              { SchoolID: this.formFilterSchedule.SchoolID }
            ]
          },
          include: ["Userapps"]
        }
        forkJoin([
          this._scheduleService.getUserByID(idProfessor).pipe(take(1)),
          this._scheduleService.getUserJobsContract(JSON.stringify(filterContratc)).pipe(take(1)),
        ]
        ).subscribe(
          data => {
            // Si hay contratos de esa persona buscar el que cumpla con el rango de hoy
            let nowDate = new Date();
            let isEnabled = false;
            let CompleteName = `${data[0].LastName1 ? data[0].LastName1 : ''}  ${data[0].LastName2 ? data[0].LastName2 : ''}  ${data[0].Name1 ? data[0].Name1 : ''} ${data[0].Name2 ? data[0].Name2 : ''} `
            if (data[1].length > 0) {
              let userContract = data[1].find(x => (new Date(x.StartContract) < nowDate && nowDate > new Date(x.EndContract)))
              if (userContract) isEnabled = true
              else isEnabled = false
            }
            else isEnabled = false
            if (isEnabled) {
              tempProfesorList.push({ value: data[0].id, title: CompleteName.toUpperCase(), })
              Object.assign(this.intermediateDataST.Professor, { [data[0].id]: CompleteName.toUpperCase() })
            }
            else {
              CompleteName += ' (NO HABILITADO-ESCOGER OTRO DOCENTE)'
              tempProfesorList.push({ value: data[0].id, title: CompleteName.toUpperCase(), })
              Object.assign(this.intermediateDataST.Professor, { [data[0].id]: CompleteName.toUpperCase() })
            }
          },
          err => { console.log('Error al consultar los usuarios  '); },
          () => {
            this.createSmartTable()
            this.createSmartTableDates()
          }
        );
      }, err => { console.log('Error'); },
        () => { }
      )
    }
    // Si no hay diferencia de docentes, simplemente preparar los dato para mostrar en la tabla
    else {
      this.createSmartTable()
      this.createSmartTableDates()

    }
  }

  createSmartTable() {
    this.subjectGroupsTable = []
    this.subjects.forEach(subject => {
      let tempData: SubjectGroup = {
        SubjectID: subject.id,
        NameSubject: subject.NameSubject,
        CourseGroupID: this.formFilterSchedule.GroupID,
        UserID: null,
        IsPhysicalTest: false,
        DateStart: this.courseOfers.find(x => x.id == this.formFilterSchedule.CourseOferID).Since,
        DateEnd: this.courseOfers.find(x => x.id == this.formFilterSchedule.CourseOferID).Until,
        IsDateChanged: false,
        HPM: subject.HPM
      }
      // Calculo de los MIDTERMS
      tempData.MidTerms = 0
      if (subject.HPM == 0)
        tempData.MidTerms = 1
      else if (subject.HPM >= 1 && subject.HPM <= 10)
        tempData.MidTerms = 1
      else if (subject.HPM >= 11 && subject.HPM < 21)
        tempData.MidTerms = 2
      else
        tempData.MidTerms = 3


      let posSubjectGroup = -1
      posSubjectGroup = this.subjectGroupsDB.findIndex(x => x.SubjectID == subject.id)
      if (posSubjectGroup >= 0) {
        tempData["id"] = this.subjectGroupsDB[posSubjectGroup].id
        tempData["UserID"] = this.subjectGroupsDB[posSubjectGroup].UserID
        tempData["IsPhysicalTest"] = this.subjectGroupsDB[posSubjectGroup].IsPhysicalTest
        tempData["DateStart"] = this.subjectGroupsDB[posSubjectGroup].DateStart
        tempData["DateEnd"] = this.subjectGroupsDB[posSubjectGroup].DateEnd
        tempData["IsDateChanged"] = this.subjectGroupsDB[posSubjectGroup].IsDateChanged
        tempData["HPM"] = this.subjectGroupsDB[posSubjectGroup].HPM
        tempData["subjects"] = this.subjectGroupsDB[posSubjectGroup].subjects
      }
      this.subjectGroupsTable.push(tempData)
    })
    this.source.load(this.subjectGroupsTable)

    this.showTable = true
  }

  createSmartTableDates() {
    let smartTabledDates = []
    this.subjectGroupsDB.forEach(subjectGroup => {
      let tempData = {
        id: subjectGroup.id,
        NameSubject: this.subjects.find(x => x.id == subjectGroup.SubjectID).NameSubject,
        SubjectID: this.subjects.find(x => x.id == subjectGroup.SubjectID).id,
        CourseGroupID: this.formFilterSchedule.GroupID,
        DateStart: moment(subjectGroup.DateStart).utc().add(1, 'days').format("YYYY-MM-DD"),
        DateEnd: moment(subjectGroup.DateEnd).utc().add(1, 'days').format("YYYY-MM-DD"),
        IsDateChanged: subjectGroup.IsDateChanged,
      }

      smartTabledDates.push(tempData)
    })
    this.sourceDates.load(smartTabledDates)
  }

  onEditConfirmDates(event): any {
    if (moment(event.newData.DateStart, 'YYYY-MM-DD').isValid() == true &&
      moment(event.newData.DateEnd, 'YYYY-MM-DD').isValid() == true
    ) {

      //SI EL EVENTO YA SE INDICA QUE LA FECHA  SE HA MODIFICADO 
      if (event.data.IsDateChanged == true) {
        this.sharedService.swalError("Error al guardar fechas", "La fecha para la materia ya ha sido modificada")
        return
      }
      var timestart = moment(event.newData.DateStart, 'YYYY-MM-DD')
      var timeEnd = moment(event.newData.DateEnd, 'YYYY-MM-DD')
      if (timestart.isSameOrAfter(timeEnd) == true) {
        this.sharedService.swalError("Error al guardar fechas", "La fecha final debe ser mayor a la inicial")
        return
      }
      var Info = {
        "id": event.data.id,
        "CourseGroupID": event.newData.CourseGroupID,
        "SubjectID": event.newData.SubjectID,
        "MidTerms": event.newData.MidTerms,
        "DateStart": moment(event.newData.DateStart).format('YYYY-MM-DD'),
        "DateEnd": moment(event.newData.DateEnd).format('YYYY-MM-DD'),
        "IsDateChanged": true

      }

      this.sharedService.swalLoading("Modificando Fecha")
      // Actualizo el subjectGroup 
      this._scheduleService.patchSubjectGroup(Info).subscribe(newData => {
        event.newData.IsDateChanged = true

        //Actualizar todos los dateRecords de acuerdo con las nuevasfechas
        let filterDateRecord = {
          where: {
            and: [
              { SubjectGroupID: event.data.id },
              { IsExtension: false }
            ]
          }
        }
        let filterDateMidTerms = {
          where: {
            and: [
              { SchoolID: this.formFilterSchedule.SchoolID }
            ]
          }

        }
        let filterMidTerms = {
          order: "MidTermNum ASC"
        }


        forkJoin([
          this._scheduleService.getDateRecords(JSON.stringify(filterDateRecord)).pipe(take(1)),
          this._scheduleService.getDateMidTerms(JSON.stringify(filterDateMidTerms)).pipe(take(1)),
          this._scheduleService.getMidTermsBySubjectGroupID(event.data.id, JSON.stringify(filterMidTerms)).pipe(take(1)),
        ]
        ).subscribe(
          (data) => {
            let daterecords = data[0]
            let dateMids = data[1]
            let midTerms = data[2]
            let dateMidData
            if (dateMids.length == 1)
              dateMidData = dateMids[0]
            if (dateMids.length > 1)
              dateMidData = dateMids[dateMids.length - 1]

            // Recorrer cada midterm y actualizar el daterecord
            from(midTerms).subscribe(midTerm => {
              var daterecord = daterecords.find(x => x.MidTermID === midTerm.id)
              var sendData = {
                id: daterecord.id,
                DateStartRecord: timestart.subtract(dateMidData["NDays"], "days").format("YYYY-MM-DD"),
                DateEndRecord: timeEnd.add(3, "days").format("YYYY-MM-DD")
              }
              this._scheduleService.patchDateRecord(sendData).pipe(take(1)).subscribe(newPatch => { })

            },
              error => { console.error("Error recorriendo midterms") },
              () => {
                event.confirm.resolve(event.newData);
                this.sharedService.sleep(1000).then(() => {
                  this.sharedService.swalEndLoad()
                  this.callSchedule();
                })

              })

            // FINALIZA -  RECORRER CADA MIDTERM

          },
          err => { console.log('Error al traer todas los recursos  '); },
          () => {

          }
        );




      })
    }

  }


  onEditConfirm(event): any {
    //valido las fechas
    if (moment(event.newData.DateStart, 'YYYY-MM-DD').isValid() == true && moment(event.newData.DateEnd, 'YYYY-MM-DD').isValid() == true) {
      var Info = {
        CourseGroupID: event.newData.CourseGroupID,
        SubjectID: event.newData.SubjectID,
        MidTerms: event.newData.MidTerms,
        DateStart: moment(event.newData.DateStart).format('YYYY-MM-DD'),
        DateEnd: moment(event.newData.DateEnd).format('YYYY-MM-DD'),
        UserID: parseInt(event.newData['UserID']),
        HPM: event.newData.HPM,
        IsPhysicalTest: event.newData.IsPhysicalTest,
        id: null
      }

      //SI EL DATO VIENE CON ID ES PORQUE YA TIENE SUBJECTGROUP, LO ADJUNTO A Info
      if (event.newData.id)
        Info["id"] = event.newData.id
    }
    else
      return this.sharedService.swalError("Error interno en fechas", "No se pudo encontrar fechas del curso")


    // VALIDO QUE EL DOCENTE QUE SE GUARDO SI ESTE HABILITADO
    let professor = Object.keys(this.intermediateDataST.Professor).find(key => key == event.newData["UserID"])
    if (this.intermediateDataST.Professor[professor].includes('NO HABILITADO-ESCOGER OTRO DOCENTE'))
      return this.sharedService.swalError("Error al asociar docente", "No se puede guardar el usuario porque no esta habilitado, por favor seleccione otro")


    // #########################
    //    1 CREO EL SUBJECGROUP
    //##########################

    this._scheduleService.patchSubjectGroup(Info).pipe(take(1)).subscribe(subjectGroupData => {
      event.newData.id = subjectGroupData.id

      // #########################
      //    2. ASOCIO A LOS ESTUDIANTES
      //##########################

      this._scheduleService.assignUserSubject(JSON.stringify({
        SubjectGroupID: subjectGroupData.id,
        CourseGroupID: subjectGroupData.CourseGroupID
      })).pipe(take(1)).subscribe(ll => {
        event.confirm.resolve(event.newData);
        this.sharedService.swalSuccess("Docente asociado correctamente", "Se ha asociado la materia al docente correctamente")

        //CARGO LOS DATOS PARA MODIFICAR LAS FECHAS
        this.callSchedule()

      }, e => {
        this.sharedService.swalError("Error asociando estudiantes", "No se pudo asociar los estudiantes, error: " + e)
      })



    }, e => {
      this.sharedService.swalError("Error asociando docente  ", "No se pudo asociar el docente, error: " + e)
    })


  }


}