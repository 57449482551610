import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private token: string;

  constructor() { }

  getToken() {
    let token = localStorage.getItem('accessToken');
    this.token = null;
    if (token) {
      this.token = token;
    }
    return this.token;
  }

}
