import { Component, OnInit } from '@angular/core';

import {
  CalendarEvent,
  CalendarEventAction,
  CalendarView,
  DAYS_OF_WEEK
} from 'angular-calendar';
import { Subject } from 'rxjs';
import { colors } from './colors';

import { SharedService } from 'src/app/services/shared.service';
import { ScheduleService } from 'src/app/services/schedule.service';
import * as moment from 'moment'
import { take } from 'rxjs/operators';
import { CourseService } from 'src/app/services/course.service';
import { ActivatedRoute } from '@angular/router';
import { CourseOffer } from 'src/app/models/courseOffer';
import { CourseGroup } from 'src/app/models/courseGroup';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'academic-calendar',
  templateUrl: 'academic-calendar.component.html',
  styleUrls: ['./academic-calendar.component.css', './academic-calendar.component.sass'],
})
export class AcademicCalendarComponent implements OnInit {
  courseOfer: CourseOffer = new CourseOffer()
  locale: string = 'es';
  weekStartsOn: number = DAYS_OF_WEEK.MONDAY;
  weekendDays: number[] = [DAYS_OF_WEEK.FRIDAY, DAYS_OF_WEEK.SATURDAY];
  excludeDays_celic: number[] = [DAYS_OF_WEEK.SATURDAY, DAYS_OF_WEEK.SUNDAY]; // Excluir días de la semana
  excludeDays_cedoc: number[] = [DAYS_OF_WEEK.SUNDAY]; // Excluir días de la semana
  excludeDays: number[] = []; // Excluir días de la semana
  dayStartHour = 6; // Configuración del horario de inicio del día
  dayEndHour = 21; // Configuración del horario de final del día
  actions: CalendarEventAction[] = [
    {
      label: '<i class="fa  fa-trash"></i>',
      a11yLabel: 'Delete',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.events = this.events.filter((iEvent) => iEvent !== event);
      },
    },
  ];
  showViews = {
    calendar: false,
    events: false
  }
  CalendarView = CalendarView;
  view = CalendarView.Week;
  viewDate = new Date();
  events: CalendarEvent[] = [];
  activeDayIsOpen = true;
  refresh = new Subject<void>();
  courseOferID: number = null
  courseGroupsOriginal: CourseGroup[] = [];
  filterShedule: { courseGroupID: number } = { courseGroupID: null }
  PLATFORM_NAME = environment.principalAppName;
  constructor(
    public sharedService: SharedService,
    private _scheduleService: ScheduleService,
    private _courseService: CourseService,
    private _actRoute: ActivatedRoute
  ) {
    if (this.PLATFORM_NAME == 'cedoc' ) this.excludeDays = this.excludeDays_cedoc;
    else this.excludeDays = this.excludeDays_celic;
  }





  ngOnInit(): void {

    this._actRoute.paramMap.subscribe(params => {
      this.courseOferID = Number(params.get('courseOferID'))
      this.getEventsCourseOfer();

    });


  }

  getEventsByGroup() {
    let index = 0;
    this.showViews.calendar = false
    this.events = [];
    let courseGroup: CourseGroup =  this.courseGroupsOriginal.find(x => x.id == this.filterShedule.courseGroupID)
    courseGroup.subjectGroups.forEach((subjectGroup) => {
      subjectGroup.scheduleSubjects.forEach(scheduleSubject => {
        let CompleteName = `${subjectGroup.userapp.LastName1 ? subjectGroup.userapp.LastName1 : ''}  ${subjectGroup.userapp.LastName2 ? subjectGroup.userapp.LastName2 : ''}  ${subjectGroup.userapp.Name1 ? subjectGroup.userapp.Name1 : ''} ${subjectGroup.userapp.Name2 ? subjectGroup.userapp.Name2 : ''} `
        this.events.push({
          title: `${subjectGroup.subjects.NameSubject} - ${courseGroup.name} - ${CompleteName} `,
          color: colors[index % colors.length],
          start: moment(scheduleSubject.HourStart).add(5, "hours").toDate(),
          end: moment(scheduleSubject.HourEnd).add(5, "hours").toDate(),
          resizable: {
            beforeStart: false,
            afterEnd: false,
          },
          draggable: false,

          meta: {
            scheduleSubjectID: scheduleSubject.id,
            subjectGroupID: subjectGroup.id,
            subject: subjectGroup.subjects,
            courseGroup: subjectGroup.coursesgroup,
            user: subjectGroup.userapp,
          },
        })


      })
    })

    this.showViews.calendar = true


  }

  getEventsCourseOfer() {
    let filter = {
      where: {
        and: [{ CourseOferID: this.courseOferID }]
      },
      include: [
        { relation: 'subjectGroups', scope: { include: ["scheduleSubjects", "userapp", "subjects"] } }
      ]
    }


    this._courseService.getCoursesOferById(this.courseOferID).pipe(take(1)).subscribe(ofer => this.courseOfer = ofer)
    this._courseService.getCourseGroups(JSON.stringify(filter)).pipe(take(1)).subscribe(courseGroups => {
      this.courseGroupsOriginal = []
      if (courseGroups.length == 0) {
        this.showViews.calendar = true
        this.sharedService.swalError("", "Aún no existen grupos para la ofera seleccionada, intenta más tarde")
        return
      }

      this.courseGroupsOriginal = [...courseGroups]


    })


  }

  // Funciones para calendar

closeOpenMonthViewDay(){

}
setView(stateView){

}

}
